const defaultViewAggregations = {
  includedPeriods: 1, // Periods to include in the aggregation
  method: 'period' // 'average', 'first', 'last' // 'period', 'balance' // period/balance are actually just the value of the period: overriden when includedPeriods === 1
}

export const optionSelectorData = {
  // Dates and timeframe
  dates: {
    min: null,
    max: null
  },
  dateOptions: {
    default: {}
  },
  periodicity: 1, // The periods being shown
  viewAggregations: defaultViewAggregations,

  // Entities and Layout
  organisationId: null,
  orgsArray: null,
  layout: ['dates'],
  // layout: ['dates', 'orgs']
  // layout: ['orgs', 'dates']

  // Filter Dates
  filterDates: {}, // data object set in the module
  optionsJournals: {}, // data object set in the module

  // Others
  decimals: 0
}

/**
 * Eg. from P&L monthly Trailing 3 months to BS
 *    -> Should become includedPeriods = 1 and method = balance
 *
 * From P&L quarterly Average 3 months to BS
 *    -> Only balance is supported
 *
 * From BS to P&L
 */
export function setOnChangeStatementType (newStatementType) {
  optionSelectorData.viewAggregations = optionSelectorData.viewAggregations || defaultViewAggregations

  if (newStatementType === 'bs') {
    optionSelectorData.viewAggregations.includedPeriods = 1
    optionSelectorData.viewAggregations.method = 'balance'
  } else if (newStatementType === 'pnl') {
    optionSelectorData.viewAggregations.includedPeriods = optionSelectorData.periodicity
    optionSelectorData.viewAggregations.method = 'period'
  }
}
