import { createGrid } from 'ag-grid-community'
import { hideElement, showElement } from '../../../helpers/dom.js'
import { optionSelectorData } from '../../../modules/option-selector/data.js'
import { makeColumnDefs } from './make-column-defs.js'
import { appAccount } from '../../../modules/user/account.js'
import { makeRows } from './make-rows.js'
import { loadData } from './load-data.js'
// import { makeColumns } from './table/make-columns.js'
// import { makeRows } from './table/make-rows.js'

const context = {
  data: {}
}

export let gridApi

/**
 *
 */
export async function updateView () {
  console.log('updateView', optionSelectorData)
  showElement('statementLoadingMessage')
  hideElement('statementContainer')

  // Params in use
  context.showOrgId = optionSelectorData.organisationId || context.showOrgId
  context.currencySymbol = optionSelectorData.currencySymbol || 'XXX'
  context.viewDecimals = optionSelectorData.decimals || 0
  context.filterDates = optionSelectorData.filterDates
  context.accountData = appAccount.accountData()
  // context.groupings = ['accounts', 'tracking1', 'tracking2']

  // Load data
  await loadData(context)
  console.log('context', context)

  const gridOptions = {
    rowData: makeRows(context.data),
    columnDefs: makeColumnDefs(context),
    defaultColDef: {
      filter: true,
      floatingFilter: true,
      onCellClicked: function (cellParams) {
        console.log(cellParams)
      }
    },
    autoSizeStrategy: {
      type: 'fitCellContents'
    },
    context
  }

  // Include Top Pinned row with total of amounts

  const containerId = 'grid'
  const gridContainerElement = document.getElementById(containerId)

  if (gridApi) {
    gridApi.setGridOption('rowData', makeRows(context.data))
  } else {
    gridApi = createGrid(gridContainerElement, gridOptions)
  }

  console.log('gridApi', gridApi)

  hideElement('statementLoadingMessage')
  showElement('statementContainer')
}
