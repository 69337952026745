
import { group, max, min, rollup } from 'd3'
import { moduleData } from '../../data.js'

import './style.less'
import htmlPageTemplate from './template.html'
import { fillString } from '../../../../../../helpers/templating/string-template.js'
import { setElementHtml } from '../../../../../helpers/dom.js'
import { showDataGrid } from './show-data-grid.js'

export function showOrdersSummary () {
  const groupedByOrder = group(moduleData.ordersLines,
    function (record) {
      return record.orderId
    })

  const groupedBySKU = group(moduleData.ordersLines, function (record) {
    return record.SKU_id
  })

  // Group by order with their settledAt value
  const rolledUp = rollup(moduleData.ordersLines,
    function (records) {
      return min(records, record => record.settledAt)
    },
    function (record) {
      return record.orderId
    }
  )
  // console.log('rolledUp', rolledUp)

  // Group orders by settled or not
  const groupedBySettled = group(rolledUp, function (record) {
    const rollupValue = record[1]
    return !(rollupValue === undefined)
  })
  console.log('groupedBySettled', groupedBySettled)

  const shipCountries = new Set()
  moduleData.ordersLines?.forEach(function (record) {
    shipCountries.add(record.shipCountry)
  })

  const html = fillString(htmlPageTemplate, {
    numberOrders: groupedByOrder.size,
    numberSKUs: groupedBySKU.size,
    createdAtMin: min(moduleData.ordersLines, function (record) { return record.createdAt }) || '??',
    createdAtMax: max(moduleData.ordersLines, function (record) { return record.createdAt }) || '??',
    numberSettled: groupedBySettled?.get(true)?.length || '-',
    numberNotSettled: groupedBySettled?.get(false)?.length || '-',
    settledAtMin: min(moduleData.ordersLines, function (record) { return record.settledAt }) || '-',
    settledAtMax: max(moduleData.ordersLines, function (record) { return record.settledAt }) || '-',
    shipCountries: Array.from(shipCountries)
  })

  setElementHtml('summaryOrdersData', html)

  activateView()
}

function activateView () {
  document.getElementById('viewOrdersData').addEventListener('click', function () {
    // console.log('moduleData', moduleData)
    // console.log('ordersDetailsSummary', ordersDetailsSummary)

    showDataGrid()
  })
}
