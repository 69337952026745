import dayjs from 'dayjs'
import { callApi } from '../../../helpers/fetch.js'

/**
 *
 * @param {Object} context
 * @param {String} context.showOrgId
 * @param {Object} [context.dates]
 * @param {String} [context.dates.min]
 * @param {String} [context.dates.max]
 * @returns
 */
export async function loadDataMonthlyValues (context) {
  console.log('context.showOrgId', context.showOrgId)

  if (!context.showOrgId) return

  // The orgIds to fetch data for
  const orgIds = []

  if (!Array.isArray(context.showOrgId)) {
    orgIds.push(context.showOrgId)
  } else {
    orgIds.push(...context.showOrgId)
  }

  // Load the data for each entity
  const promises = []
  for (const orgId of orgIds) {
    const orgData = getOrgData(context.accountData?._accountOrganisations, orgId)

    const promise = loadOneEntity(orgId, context.dates, orgData)
    promises.push(promise)
  }
  const loadedData = await Promise.all(promises)

  // Concatene the values together
  const results = {
    monthlyValues: []
  }

  loadedData.forEach(function (oneOrgData) {
    results.monthlyValues = results.monthlyValues.concat(oneOrgData.monthlyValues)
  })

  // Return
  console.log('results', results)
  return results
}

/**
 *
 * @param {*} orgId
 * @param {*} dates
 * @returns
 */
async function loadOneEntity (orgId, dates, orgData) {
  console.log('loadOneEntity', dates)
  const monthFrom = dates.min || dayjs().add(-1, 'month').format('YYYY-MM')
  const monthTo = dates.max || dayjs().format('YYYY-MM')
  console.log('monthFrom', monthFrom, 'monthTo', monthTo)

  // Pagination
  // const monthsToLoad = []
  // let currentMonth = dayjs(monthFrom, 'YYYY-MM')
  // while (currentMonth.format('YYYY-MM') <= monthTo) {
  //   console.log(currentMonth.format('YYYY-MM'), ' <= ', monthTo)
  //   const month = currentMonth.format('YYYY-MM')
  //   monthsToLoad.push(month)

  //   console.log('currentMonth', currentMonth)
  //   currentMonth = currentMonth.add(1, 'month')
  // }
  // console.log('monthsToLoad', monthsToLoad)

  // const monthlyValues = []
  // for (const oneMonth of monthsToLoad) {
  //   const pageData = await loadOneEntityDataPage(orgId, oneMonth, oneMonth)
  //   pageData.forEach(function (one) {
  //     one.entity = orgData
  //     monthlyValues.push(one)
  //   })
  // }
  // return {
  //   monthlyValues
  // }
  // End of pagination

  // Method: Without pagination
  const loadedData = await callApi('/api/finlogic/data/:orgId/stanjournals-monthly-values/:monthFrom/:monthTo', {
    orgId,
    monthFrom,
    monthTo
  })

  // Add the org info to each value
  loadedData.monthlyValues.forEach(function (oneValue) {
    oneValue.entity = orgData
  })

  return loadedData
}

/**
 * Dataset can be large, so we paginate
 */
// async function loadOneEntityDataPage (orgId, monthFrom, monthTo) {
//   const loadedData = await callApi('/api/finlogic/data/:orgId/stanjournals-monthly-values/:monthFrom/:monthTo', {
//     orgId,
//     monthFrom,
//     monthTo
//   })

//   return loadedData.monthlyValues
// }

function getOrgData (accountDataAccountOrg, orgId) {
  const org = accountDataAccountOrg?.find(function (one) {
    return one._xeroOrganisation._id === orgId
  })

  return org?._xeroOrganisation
}
