import { format, sum } from 'd3'

export function summarise (combinationMap) {
  summariseOrders(combinationMap)
}

function summariseOrders (combinationMap) {
  console.log('summariseOrders', combinationMap)
  combinationMap?.forEach(function (mapValue, orderId) {
    // console.log(orderId, mapValue)
    const summaryOrder = summariseOrder(mapValue.orderLines)
    mapValue.summaryOrder = summaryOrder

    mapValue.summarySettlement = summariseSettlement(mapValue.settlementLines)
    mapValue.comparisons = summariseDiscrepancies(mapValue)
  })
}

const propertiesToCompare = [
  'revenueGross',
  'revenueNet'
]

function summariseDiscrepancies (mapValue) {
  const comparisons = {}

  propertiesToCompare?.forEach(function (propertyName) {
    const propertyComparison = {
      variance: null,
      isMatching: null
    }
    const val1 = mapValue.summaryOrder?.[propertyName]
    const val2 = mapValue.summarySettlement?.[propertyName]

    propertyComparison.isMatching = val1 === val2
    const variance = val1 - val2
    propertyComparison.variance = format('.2f')(variance)

    comparisons[propertyName] = propertyComparison
  })
  console.log('summariseDiscrepancies', mapValue, comparisons)

  return comparisons
}

/**
 *
 * @param {*} orderLines
 * @returns
 */
function summariseOrder (orderLines) {
  // console.log('summariseOrder', orderLines)
  // order might not have data (only settlement for eg)
  if (!orderLines) return {}

  const summary = {
    createdAt: orderLines[0].createdAt || null,
    revenueGross: summarySum(orderLines, 'revenueGross'),
    revenueNet: summarySum(orderLines, 'revenueNet')
  }
  return summary
}

/**
 *
 * @param {*} orderLines
 * @returns
 */
function summariseSettlement (settlementLines) {
  if (!settlementLines) return {}

  const summary = {
    settledAt: settlementLines[0].settledAt || null,
    revenueGross: summarySum(settlementLines, 'revenueGross'),
    revenueNet: summarySum(settlementLines, 'revenueNet')
  }
  return summary
}

function summarySum (records, propertyName) {
  const sumValue = sum(records, function (record) { return record[propertyName] })

  return format('.2f')(sumValue)
}
