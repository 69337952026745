
// App config
import * as configVar from '../../config/config.js'
import { showCalendly } from '../../modules/calendly/calendly.js'

// Page Module
import htmlIndex from './index.html'

export async function show () {
  console.log('module show home')

  // If login required
  // if (mUser.enforceRestrictedPage()) return

  // Ensure the page content element is available
  const DOMelement = document.getElementById(configVar.contentElementId)
  if (!DOMelement) return

  // Create the page DOM Element:

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')
  contentElement.innerHTML = htmlIndex

  // Inject the page in the app container
  DOMelement.innerHTML = ''
  DOMelement.append(contentElement)

  showCalendly({
    containerId: 'showCalendlyBooking'
  })
}
