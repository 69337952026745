
// App config
import * as configVar from '../../config/config.js'

// Dependencies
import * as mUser from '../../modules/user/user.js'
import * as mAccount from '../../modules/user/account.js'

import { apiEndpointCurrencyList } from '../../../helpers/fx/settings.js'

// Page Module
import htmlIndex from './index.html'
import { callApi } from '../../helpers/fetch.js'
import { showGrid } from './show-grid.js'

/**
 *
 * @returns
 */
export async function show () {
  console.log('module show currencies')

  if (mUser.enforceRestrictedPage()) return

  // Ensure the page content element is available
  const DOMelement = document.getElementById(configVar.contentElementId)
  if (!DOMelement) return

  // Create the page DOM Element:

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')
  contentElement.innerHTML = htmlIndex

  // Inject the page in the app container
  DOMelement.innerHTML = ''
  DOMelement.append(contentElement)

  const accountData = mAccount.accountData()
  document.title = ['Currencies:', accountData.name].join(' ')

  showCurrencies()
  listeners()
}

function listeners () {}

async function showCurrencies () {
  const accountData = mAccount.accountData()

  if (!accountData) return
  if (!accountData._id) return

  const currencyList = await loadCurrencyList()
  console.log('currencyList', currencyList)

  const currencyListElement = document.getElementById('currencyList')
  if (!currencyListElement) return

  showGrid(accountData, currencyList)
}

/**
 *
 * @returns
 */
async function loadCurrencyList () {
  console.log('loadCurrencyList')

  const apiUrl = apiEndpointCurrencyList
  const data = await callApi(apiUrl)
  return data
}
