import { ascending } from 'd3'
import { makeMonthSeries } from './month-series.js'
import { valueMaker } from './value-maker.js'

export function makeRows (context) {
  console.log('makeRows', context)

  const rows = groupValuesByRow(context)

  const includedRows = includeOnlyVisibleRows(rows, context)

  // Sort
  includedRows.sort(function (r1, r2) {
    return ascending(r1.chartOfAccount?.code, r2.chartOfAccount?.code)
  })

  includedRows.sort(function (r1, r2) {
    return ascending(r1.chartOfAccount?.statement, r2.chartOfAccount?.statement)
  })

  return includedRows
}

function includeOnlyVisibleRows (rows, context) {
  const monthsArray = makeMonthSeries(context)

  const includedRows = []

  rows?.forEach(function (row) {
    let isRowToInclude = false

    monthsArray?.forEach(function (targetMonth) {
      const cellValue = valueMaker(row, targetMonth, context)
      if (cellValue) isRowToInclude = true
    })

    if (isRowToInclude) includedRows.push(row)
  })

  return includedRows
}

/**
 *
 * @param {*} context
 * @returns
 */
function groupValuesByRow (context) {
  const rows = []
  const indexed = {}

  context?.data?.statementData.forEach(function (oneEntry) {
    if (context.statementType !== oneEntry.chartOfAccount?.statement) return

    const key = [oneEntry.chartOfAccount?.statement, oneEntry.chartOfAccount?.name]

    if (!indexed[key]) {
      const obj = {
        chartOfAccount: oneEntry.chartOfAccount,
        values: []
      }
      indexed[key] = obj
      rows.push(obj)
    }

    indexed[key].values.push(oneEntry)
  })

  return rows
}
