
// Module
import { setElementHtml } from '../../../helpers/dom.js'

import { optionSelectorData } from '../data.js'
import { executeOnChange } from '../onchange.js'

import htmlPeriods from './periods.html'

export function enableOptionsPeriods (params) {
  // console.log('enableOptionsPeriods', params)
  if (params?.dateOptions?.default) {
    applyShortcut(params, params?.dateOptions?.default, false)
  }
}

/**
 *
 * @param {*} params
 * @returns
 */
export function activateOptionsPeriods (params) {
  // console.log('activateOptionsPeriods', params)
  // console.log('optionSelectorData', optionSelectorData)

  const dom = setElementHtml('optionSelectorContent', htmlPeriods)
  if (!dom) return

  showSelector(params)
  listeners(params)
  showShortcuts(params)

  const areDatesAlreadySet = optionSelectorData.dates?.min && optionSelectorData.dates?.max

  if (params?.dateOptions?.default && !areDatesAlreadySet) {
    applyShortcut(params, params?.dateOptions?.default, false)
  }

  updateDOM('periodFrom', optionSelectorData.dates?.min)
  updateDOM('periodTo', optionSelectorData.dates?.max)
}
/**
 * Listeners for the date selector
 */
function listeners (params) {
  // console.log('date listeners')
  const optionSelectorsElement = document.getElementById('optionPeriodSelector')
  // console.log('optionSelectorsElement', optionSelectorsElement)

  optionSelectorsElement.addEventListener('change', function (changeEvent) {
    // console.log('changeEvent', changeEvent)
    const target = changeEvent.target.closest('select')
    if (!target) return

    console.log(target.id, target.value)

    if (target.id === 'periodFrom') {
      optionSelectorData.dates.min = target.value
    }
    if (target.id === 'periodTo') {
      optionSelectorData.dates.max = target.value
    }

    executeOnChange(params)
  })
}

function showSelector (params) {
  setElementHtml('periodFrom', selectorSetup(params, 'first').join(''))
  setElementHtml('periodTo', selectorSetup(params, 'last').join(''))
}

/**
 *
 * @param {String} selectIndexType
 * @returns
 */
export function selectorSetup (params, selectIndexType) {
  // console.log('selectorSetup', params)
  const selectOptionsHtml = []

  const dateseries = params?.dateseries
  dateseries?.forEach(function (oneDate, index) {
    let optionHtml = '<option value=":date" :isSelected>:date</option>'.replaceAll(':date', oneDate)
    if (selectIndexType === 'first' && index === 0) {
      optionHtml = optionHtml.replace(':isSelected', 'selected')
    } else if (selectIndexType === 'last' && index === dateseries.length - 1) {
      optionHtml = optionHtml.replace(':isSelected', 'selected')
    } else {
      optionHtml = optionHtml.replace(':isSelected', '')
    }
    selectOptionsHtml.push(optionHtml)
  })

  // Make the date series anti-chronological for recent dates to be at the top
  selectOptionsHtml.reverse()
  return selectOptionsHtml
}

function showShortcuts (params) {
  const shortcuts = [
    {
      label: 'past 3 months',
      from: 'relative:-3',
      to: 'relative:-1'
    },
    {
      label: '6 months',
      from: 'relative:-6',
      to: 'relative:-1'
    },
    {
      label: '12 months',
      from: 'relative:-12',
      to: 'relative:-1'
    }
    // {
    //   label: '9 previous months',
    //   from: 'relative:-15',
    //   to: 'relative:-6'
    // }
  ]

  const shortcutsContainer = document.getElementById('optionPeriodShortcuts')
  shortcutsContainer.innerText = ''

  shortcuts.forEach(function (oneShortcut) {
    const shortcutElement = document.createElement('div')
    shortcutElement.classList.add('level-item')
    shortcutElement.classList.add('periodShortcut')
    shortcutElement.classList.add('link')
    shortcutElement.setAttribute('from', oneShortcut.from)
    shortcutElement.setAttribute('to', oneShortcut.to)
    shortcutElement.innerText = oneShortcut.label

    shortcutsContainer.append(shortcutElement)
  })

  listenShortcuts(params)
}

function listenShortcuts (params) {
  const shortcutsContainer = document.getElementById('optionPeriodShortcuts')
  shortcutsContainer.addEventListener('click', function (clickEvent) {
    const target = clickEvent.target.closest('div.periodShortcut')
    if (!target) return

    const from = target.getAttribute('from')
    const to = target.getAttribute('to')

    applyShortcut(params, { from, to })
  })
}

function applyShortcut (params, shortcutParams, executeCallback) {
  const { from, to } = shortcutParams

  const fromDetails = from.split(':')
  const toDetails = to.split(':')

  applySelectionOption(params, 'periodFrom', fromDetails, 'min')
  applySelectionOption(params, 'periodTo', toDetails, 'max')

  // !! This means it might be executed on load
  if (executeCallback === false) return

  executeOnChange(params)
}

function applySelectionOption (params, targetElementId, selectionDetails, optionStorageProperty) {
  // console.log('applySelectionOption', targetElementId, selectionDetails, optionStorageProperty)
  const dateseries = params.dateseries

  // Only managing relative dates for now
  if (!(selectionDetails[0] === 'relative')) return

  const selectionDetailsRelativePeriod = +selectionDetails[1]

  const targetIndex = Math.max(0, dateseries.length + selectionDetailsRelativePeriod - 1)
  // console.log(dateseries.length, selectionDetailsRelativePeriod, targetIndex)

  optionSelectorData.dates[optionStorageProperty] = dateseries[targetIndex]
  // console.log('dateseries[targetIndex]', dateseries[targetIndex])

  const selectedValue = dateseries[targetIndex]
  updateDOM(targetElementId, selectedValue)
}

function updateDOM (targetElementId, selectedValue) {
// Apply in the DOM if available
  const periodSelectorElement = document.getElementById(targetElementId)
  if (!periodSelectorElement) return

  // console.log('periodSelectorElement', periodSelectorElement)
  const selector = ['option[value="', selectedValue, '"]'].join('')
  const targetPeriodSelectorOptionElement = periodSelectorElement.querySelector(selector)
  // console.log(selector, 'targetPeriodSelectorOptionElement', targetPeriodSelectorOptionElement)
  targetPeriodSelectorOptionElement.selected = true
}
