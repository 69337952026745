
export function showOrgUsers (byUsersAndAccounts) {
  const containerElement = document.getElementById('organisationUsers')
  containerElement.classList.remove('is-hidden')

  const contentElement = containerElement.querySelector('.content')
  contentElement.innerHTML = ''

  Object.values(byUsersAndAccounts.byUser).forEach(function (oneUser) {
    const userElement = document.createElement('div')
    userElement.classList.add('content')

    const nameElement = document.createElement('span')
    nameElement.classList.add('bold')
    nameElement.innerText = oneUser.user?.name + ' (' + oneUser.user?.email + ')'
    userElement.append(nameElement)

    const userAccountsElements = document.createElement('div')

    oneUser.accounts.forEach(function (oneAccount) {
      const accountElement = document.createElement('div')
      accountElement.innerHTML = '> ' + oneAccount.account?.name + ' [' + oneAccount.rights?.join(', ') + ']' + (oneAccount.isSupport ? ' <b>SUPPORT</b>' : '')
      userAccountsElements.append(accountElement)
    })
    userElement.append(userAccountsElements)
    contentElement.append(userElement)
  })
}
