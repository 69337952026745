
// App config
import { fillString } from '../../../../helpers/templating/string-template.js'
import * as configVar from '../../../config/config.js'
import { callApi } from '../../../helpers/fetch.js'

// Dependencies
import * as mUser from '../../../modules/user/user.js'

// Page Module
import htmlTrialBalancesTemplate from './trial-balances.html'

/**
 *
 * @returns
 */
export async function showOrgTrialBalances (orgId) {
  console.log('OrgInfo TrialBalances show()', orgId)

  if (mUser.enforceRestrictedPage()) return

  // Ensure the page content element is available
  const DOMelement = document.getElementById(configVar.contentElementId)
  if (!DOMelement) return

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')
  contentElement.innerHTML = htmlTrialBalancesTemplate

  // Inject the page in the app container
  DOMelement.innerHTML = ''
  DOMelement.append(contentElement)

  loadTrialBalances(orgId)
}

/**
 *
 * @param {*} orgId
 * @returns
 */
async function loadTrialBalances (orgId) {
  const apiUrl = '/api/xero/:orgId/trial-balances'
  const data = await callApi(apiUrl, {
    orgId
  })
  adminShowTrialBalances(data.dataPrep)
}

function adminShowTrialBalances (data) {
  console.log('adminShowTrialBalances', data)
  const tableElement = document.getElementById('trialBalancesTable')
  data.trialBalances?.forEach(function (oneTrialBalanceDoc) {
    const rowHtml = fillString('<tr><td>:date</td><td>:month</td><td>:numberValues</td></tr>', {
      date: oneTrialBalanceDoc.date,
      month: oneTrialBalanceDoc.month,
      numberValues: oneTrialBalanceDoc.values.length
    })

    const rowElement = document.createElement('tr')
    rowElement.innerHTML = rowHtml
    tableElement.append(rowElement)
  })
}
