// import { createGrid } from 'ag-grid-community'
import { hideElement, showElement, showLoading } from '../../../helpers/dom.js'
import { optionSelectorData } from '../../../modules/option-selector/data.js'
import { loadDataMonthlyValues } from './load-data-monthly-values.js'
import { makeColumnDefs } from './make-column-defs.js'
import { appAccount } from '../../../modules/user/account.js'
import { createGrid } from 'ag-grid-community'
import { makeRows } from './make-rows.js'

export const context = {
  data: {}
}

export let gridApi

/**
 *
 */
export async function updateView () {
  console.log('updateView', optionSelectorData)
  showElement('statementLoadingMessage')
  hideElement('statementContainer')
  showLoading('statementLoadingMessage')

  // Params in use
  context.showOrgId = optionSelectorData.organisationId || context.showOrgId
  context.currencySymbol = optionSelectorData.currencySymbol || 'XXX'
  context.viewDecimals = optionSelectorData.decimals || 0
  context.dates = optionSelectorData.dates
  context.accountData = appAccount.accountData()
  console.log('context', context)

  // Load data
  const response = await loadDataMonthlyValues(context)
  context.data = response

  const containerId = 'grid'
  const gridContainerElement = document.getElementById(containerId)
  const containerHasContent = gridContainerElement.querySelectorAll('div')?.length

  const gridRows = makeRows(context)
  const gridColumns = makeColumnDefs(context)
  // console.log('gridRows', gridRows)
  // console.log('gridColumns', gridColumns)

  // console.log('grid', gridRows, gridColumns)
  // console.log('gridApi', gridApi, containerHasContent, gridContainerElement)

  if (gridApi && containerHasContent) {
    console.log('UPDATE the grid')
    gridApi.updateGridOptions({
      rowData: gridRows,
      columnDefs: gridColumns
    })
  } else {
    console.log('CREATE the grid')
    const gridOptions = {
      rowData: gridRows,
      columnDefs: gridColumns,
      defaultColDef: {
        filter: true,
        floatingFilter: true
      },
      autoSizeStrategy: {
        type: 'fitCellContents'
      },
      context
    }

    gridApi = createGrid(gridContainerElement, gridOptions)
  }

  console.log('gridApi', gridApi)

  hideElement('statementLoadingMessage')
  showElement('statementContainer')
}
