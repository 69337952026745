
// App config
import * as configVar from '../../../config/config.js'

// Dependencies
import * as mUser from '../../../modules/user/user.js'

// Page Module
import htmlIndex from './index.html'
import { shortenNumber } from '../../../../helpers/helpers/numbers.js'
import { callApi } from '../../../helpers/fetch.js'
import { showCurrentTimestamp } from '../utilities/timestamp.js'
import { fillString } from '../../../../helpers/templating/string-template.js'

/**
 *
 * @returns
 */
export async function showPageListOrgs () {
  console.log('showPageListOrgs')

  if (mUser.enforceRestrictedPage({ allowNoAccount: true })) return

  // Ensure the page content element is available
  const DOMelement = document.getElementById(configVar.contentElementId)
  if (!DOMelement) return

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')
  contentElement.innerHTML = htmlIndex

  // Inject the page in the app container
  DOMelement.innerHTML = ''
  DOMelement.append(contentElement)

  showCurrentTimestamp('currentTimestamp')
  showListOfOrgs()
}

async function showListOfOrgs () {
  const containerElement = document.getElementById('orgsList')

  const listOfOrgs = await loadListOfOrgs()
  console.log('listOfOrgs', listOfOrgs)

  const htmlList = []
  listOfOrgs?.forEach(function (one) {
    const oneOrgHtml = `<div class="box">
      <b>:orgName</b>
      <br><a href=":urlToAdmin" target="_blank">Go to Admin</a>
      <br>Journals cover :firstJournal to :lastJournal (:numberJournals journals)
      <br>Data size: :dataSize
    </div>`

    const urlToAdmin = fillString('/app/admin/org-info/:orgId/infos', {
      orgId: one._id
    })

    const html = fillString(oneOrgHtml, {
      orgName: one.name,
      urlToAdmin,
      firstJournal: one.firstJournal,
      lastJournal: one.lastJournal,
      numberJournals: one.numberJournals,
      dataSize: shortenNumber(one.size, 1)
    })

    htmlList.push(html)
  })
  containerElement.innerHTML = htmlList.join('')
}

/**
 * (dis)associate appAccount and Org
 * @param {*} params
 * @param {String} params.method
 */
async function loadListOfOrgs () {
  const apiUrl = '/api/xero/sync/info/orgs'
  const data = await callApi(apiUrl)
  return data
}
