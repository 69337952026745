import { createGrid } from 'ag-grid-community'
import { isCurrencyActive } from './is-currency-active.js'

export function showGrid (accountData, currencyList) {
  const gridOptions = {
    rowData: currencyList.list,
    columnDefs: makeColumnDefs(accountData),
    defaultColDef: {
      filter: true,
      floatingFilter: true
    },
    autoSizeStrategy: {
      type: 'fitCellContents'
    }
  }

  console.log('gridOptions', gridOptions)

  const containerId = 'grid'
  const gridContainerElement = document.getElementById(containerId)
  console.log('gridContainerElement', gridContainerElement)

  createGrid(gridContainerElement, gridOptions)
}

function makeColumnDefs (accountData) {
  const columnDefs = []

  columnDefs.push({
    headerName: 'Code',
    valueGetter: function (cellParams) {
      return cellParams.data.symbol
    },
    pinned: 'left'
  })

  columnDefs.push({
    headerName: 'Active?',
    valueGetter: function (cellParams) {
      const targetSymbol = cellParams.data.symbol
      const isActive = isCurrencyActive(accountData, targetSymbol)

      return isActive
    },
    pinned: 'left'
  })

  columnDefs.push({
    headerName: 'Currency',
    valueGetter: function (cellParams) {
      return cellParams.data.name
    }
  })

  return columnDefs
}
