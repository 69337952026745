
export default function enableOptionsJournals (selectorOptions, optionSelectorData, privateInstance) {
  console.log('enableOptionsJournals', selectorOptions, optionSelectorData, privateInstance)

  // Take the Options to the sub module itself
  useOptionSelectorParams(selectorOptions, privateInstance)

  // Take the module values to the parent Selector
  passModuleValuesToOptionSelector(optionSelectorData, privateInstance)

  // Set the callback functions
  privateInstance.callbacks.onChangeInternal = function () {
    passModuleValuesToOptionSelector(optionSelectorData, privateInstance)
  }
  privateInstance.callbacks.onChange = selectorOptions.onChange
}

/**
 *
 * @param {*} optionSelectorData
 * @param {*} module
 */
function passModuleValuesToOptionSelector (optionSelectorData, privateInstance) {
  optionSelectorData.optionsJournals.grouping = privateInstance.api.getValue()
}

/**
 * Takes the optional defaults given to the OptionSelector and bring them to the module
 * @param {*} selectorOptions
 * @param {*} module
 */
function useOptionSelectorParams (selectorOptions, privateInstance) {
  privateInstance.api.setValue(selectorOptions?.optionsJournals?.defaults?.grouping)
}
