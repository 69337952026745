
// App config
import * as configVar from '../../../config/config.js'
import { callApi } from '../../../helpers/fetch.js'

// Dependencies
import * as mUser from '../../../modules/user/user.js'

// Page Module
import htmlIndex from './index.html'

/**
 *
 * @returns
 */
export async function showOrgAccounts (orgId) {
  console.log('OrgInfo show()', orgId)

  if (mUser.enforceRestrictedPage({ allowNoAccount: true })) return

  // Ensure the page content element is available
  const DOMelement = document.getElementById(configVar.contentElementId)
  if (!DOMelement) return

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')
  contentElement.innerHTML = htmlIndex

  // Inject the page in the app container
  DOMelement.innerHTML = ''
  DOMelement.append(contentElement)

  showAccounts(orgId)
}

async function showAccounts (orgId) {
  const containerElement = document.getElementById('accounts')
  containerElement.innerText = ''

  const listOfAccounts = await loadAccounts(orgId)
  console.log('listOfAccounts', listOfAccounts)

  listOfAccounts?.forEach(function (oneAccount) {
    const syncElement = generateOneAccountElement(oneAccount)
    containerElement.appendChild(syncElement)
  })
}

function generateOneAccountElement (accountData) {
  const accountElement = document.createElement('div')
  accountElement.classList.add('oneElement')

  const elementHtml = []

  elementHtml.push('<h4>' + accountData.details?.code + ' ' + accountData.details?.name + '</h4>')
  elementHtml.push('<p>accountId:: ' + accountData._id + '</p>')
  elementHtml.push('<p>accountXeroId:: ' + accountData.accountXeroId + '</p>')
  elementHtml.push('<p>type:: ' + accountData.details?.type + '</p>')
  elementHtml.push('<p>_class:: ' + accountData.details?._class + '</p>')

  accountElement.innerHTML = elementHtml.join('')

  return accountElement
}

/**
 *
 * @param {*} orgId
 * @returns
 */
async function loadAccounts (orgId) {
  const apiUrl = '/api/xero/:orgId/data-accounts'
  const data = await callApi(apiUrl, {
    orgId
  })
  return data.dataAccounts
}
