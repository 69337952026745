import { fillString } from '../../../../../helpers/templating/string-template.js'
import { setElementHtml } from '../../../../helpers/dom.js'
import addListeners from './add-listeners.js'

import htmlTemplate from './date-range.html'

export default function renderHtmlElement (privateInstance) {
  console.log('renderHtmlElement', privateInstance)
  const containerId = privateInstance.data?.containerId
  const selectorId = privateInstance.data?.selectorId

  const htmlElement = renderBaseHtmlElement(containerId, selectorId)
  // console.log('htmlElement', htmlElement)
  if (!htmlElement) {
    console.error('htmlElement not created')
  }
  privateInstance.data.htmlElement = htmlElement

  // Update the render with the active values
  updateRender(privateInstance)

  // Add the Listeners
  addListeners(privateInstance)
}

function renderBaseHtmlElement (containerId, selectorId) {
  // console.log('renderBaseHtmlElement', containerId, selectorId)
  const elementHtml = fillString(htmlTemplate, { elementId: selectorId })
  const dom = setElementHtml(containerId, elementHtml)
  if (!dom) return

  const createdElement = document.getElementById(selectorId)
  if (!createdElement) return

  return createdElement
}

/**
 * From JS to HTML
 */
function updateRender (privateInstance) {
  console.log('updateRender', privateInstance)
  const htmlElement = privateInstance.data.htmlElement

  setInnerText(htmlElement, 'label-main', privateInstance.data.labels.main)
  setInnerText(htmlElement, 'label-from', privateInstance.data.labels.from)
  setInnerText(htmlElement, 'label-to', privateInstance.data.labels.to)

  setFromInputValue(htmlElement, 'value-from', privateInstance.data.values.from)
  setFromInputValue(htmlElement, 'value-to', privateInstance.data.values.to)
}

/**
 *
 * @param {Element} htmlElement
 * @param {String} target
 * @param {String} value
 * @returns
 */
function setInnerText (htmlElement, target, value) {
  const element = htmlElement?.querySelector('[d-id="' + target + '"]')
  if (!element) return
  element.innerText = value
}

/**
 *
 * @param {Element} htmlElement
 * @param {String} target
 * @param {String} value
 * @returns
 */
function setFromInputValue (htmlElement, target, value) {
  const element = htmlElement?.querySelector('[d-id="' + target + '"]')
  if (!element) return
  element.value = value
}
