import dayjs from 'dayjs'
import { accessObjectPropertyValue } from '../../../../../helpers/data/objects.js'
import { fillString } from '../../../../../helpers/templating/string-template.js'
import { timeDuration } from '../../../../../helpers/helpers/time.js'

/*
  headerName: 'Entity',
  // field: 'entityName',
  valueGetter: function (cellParams) {
  // cellParams.data
  },
  // valueFormatter: function(cellParams) {},
  cellRenderer: function (cellParams) {
  },
  // cellClass: [],
  // type: 'numericColumn',
  width: 250,
  pinned: 'left',
  filter: true
*/

export function makeColumnDefs () {
  const columnDefs = []

  // Entity
  columnDefs.push({
    headerName: 'Entity',
    // field: 'entityName',
    valueGetter: function (cellParams) {
      const entityName = accessObjectPropertyValue('_xeroOrganisation.details.name', cellParams.data)
      return entityName
    },
    // valueFormatter: function(cellParams) {},
    cellRenderer: function (cellParams) {
      const entityName = accessObjectPropertyValue('_xeroOrganisation.details.name', cellParams.data)
      const entityId = accessObjectPropertyValue('_xeroOrganisation._id', cellParams.data)

      const urlToOrgInfo = fillString('/app/admin/org-info/:orgId/infos', {
        orgId: entityId
      })
      return fillString('<a href=":urlToOrgInfo">:entityName</a>', {
        urlToOrgInfo,
        entityName
      })
    },
    width: 250,
    pinned: 'left'
  })

  // Status
  columnDefs.push({
    field: 'status',
    cellRenderer: function (cellParams) {
      const syncId = cellParams.data._id
      const linkPattern = '<a href="/app/admin/sync/:syncId" target="_blank">:status</a>'
      const render = fillString(linkPattern, {
        syncId,
        status: cellParams.value
      })

      return render
    },
    width: 100,
    pinned: 'left'
  })

  // Origin
  columnDefs.push({
    field: 'origin',
    width: 100
  })

  // Created At
  columnDefs.push({
    headerName: 'Created At',
    valueGetter: function (cellParams) {
      const timestamp = accessObjectPropertyValue('createdAt', cellParams.data)
      const timeDayJs = dayjs(timestamp)
      return timeDayJs.format('YYYY-MM-DD @ HH:mm:ss')
    }
  })

  // Updated At
  columnDefs.push({
    headerName: 'Updated At',
    valueGetter: function (cellParams) {
      const timestamp = accessObjectPropertyValue('updatedAt', cellParams.data)
      const timeDayJs = dayjs(timestamp)
      return timeDayJs.format('YYYY-MM-DD @ HH:mm:ss')
    }
  })

  // Scheduled
  columnDefs.push({
    headerName: 'Scheduled At',
    valueGetter: function (cellParams) {
      const timestamp = accessObjectPropertyValue('scheduledTime', cellParams.data)
      if (!timestamp) return null

      const timeDayJs = dayjs(timestamp)
      return timeDayJs.format('YYYY-MM-DD @ HH:mm:ss')
    }
  })

  // Started
  columnDefs.push({
    headerName: 'Started At',
    valueGetter: function (cellParams) {
      const timestamp = accessObjectPropertyValue('startedAt', cellParams.data)
      if (!timestamp) return null

      const timeDayJs = dayjs(timestamp)
      return timeDayJs.format('YYYY-MM-DD @ HH:mm:ss')
    }
  })

  // Ended
  columnDefs.push({
    headerName: 'Ended At',
    valueGetter: function (cellParams) {
      const timestamp = accessObjectPropertyValue('endedAt', cellParams.data)
      if (!timestamp) return null

      const timeDayJs = dayjs(timestamp)
      return timeDayJs.format('YYYY-MM-DD @ HH:mm:ss')
    }
  })

  // Duration
  columnDefs.push({
    headerName: 'Duration',
    valueGetter: function (cellParams) {
      const startedAt = accessObjectPropertyValue('startedAt', cellParams.data)
      const endedAt = accessObjectPropertyValue('endedAt', cellParams.data)
      if (!startedAt) return null

      return timeDuration(startedAt, endedAt)?.timeText || null
      // return dayjs(startedAt).diff(dayjs(endedAt))
    }
  })

  // API Calls
  columnDefs.push({
    field: 'totalApiCalls',
    type: 'numericColumn'
  })

  // Has Error
  columnDefs.push({
    field: 'hasError'
  })

  // Tokens
  columnDefs.push({
    field: 'details.numberOfTokens'
  })

  return columnDefs
}
