import { fillString } from '../../../../../helpers/templating/string-template.js'
import { loadOrgTokens } from './load-tokens.js'
import { tokenListeners } from './token-listeners.js'

/**
 *
 * @param {*} orgId
 */
export async function showOrgTokens (orgId) {
  const containerElement = document.getElementById('organisationTokens')
  containerElement.classList.remove('is-hidden')

  const orgTokenInfos = await loadOrgTokens(orgId)
  console.log('orgTokenInfos', orgTokenInfos)

  const contentElement = containerElement.querySelector('.content')
  contentElement.innerHTML = 'Number Tokens: ' + orgTokenInfos.numberOfTokens

  const footerElement = containerElement.querySelector('footer')
  footerElement.classList.remove('is-hidden')

  // Link to Tokens page
  const targetElementsLinksToTokens = containerElement.querySelectorAll('[d-data="orgLinkTokens"]')
  const urlToTokens = fillString('/app/admin/tokens/:orgId', {
    orgId
  })
  const linkToTokens = fillString('<a href=":urlToTokens">See Tokens</a>', {
    urlToTokens
  })

  targetElementsLinksToTokens.forEach(function (oneElement) {
    oneElement.innerHTML = linkToTokens
  })

  tokenListeners(orgId)
}
