import { group } from 'd3'
import { summarise } from './summarise.js'

export function combineOrdersAndSettlements (ordersLines, settlements) {
  const ordersData = group(ordersLines,
    function (record) {
      return record.orderId
    })

  const settlementsData = group(settlements,
    function (record) {
      return record.orderId
    })
  console.log('ordersData', ordersData)
  console.log('settlementsData', settlementsData)

  const combinationMap = new Map()

  includeDataset(combinationMap, ordersData, 'orderLines')
  includeDataset(combinationMap, settlementsData, 'settlementLines')

  console.log('combinationMap', combinationMap)

  summarise(combinationMap)

  return combinationMap
}

function includeDataset (combinationMap, dataset, propertyToStoreRecords) {
  dataset?.forEach(function (records, key) {
    const combinedRecord = getCombinedRecord(combinationMap, key)

    combinedRecord[propertyToStoreRecords] = records
  })
}

/**
 * Access the Map value, or create it: allows to set the base value when first creating the Map entry
 * @param {Map} recordsMap
 * @param {String} mapEntryId
 * @returns
 */
function getCombinedRecord (recordsMap, mapEntryId) {
  let data
  data = recordsMap.get(mapEntryId)
  if (!data) {
    recordsMap.set(mapEntryId, {})
    data = recordsMap.get(mapEntryId)
  }

  return data
}
