
// import * as mOptionsPeriodicity from './periodicity/options-periodicity.js'
// import * as mOptionsPeriodsIncluded from './periods/options-value-aggregations.js'
// import * as mOptionsOrganisations from './entities/options-organisations.js'
// import * as mOptionsForecast from './forecasting-options/forecasting-options.js'

import htmlOptionSelectorTemplate from './option-selector.html'

import { enableConsole } from '../../../helpers/logging/console.js'
import { setElementHtml, showElement } from '../../helpers/dom.js'

import './option-selector.less'
import { makeMenu } from './make-menu.js'
import { optionSelectorData } from './data.js'

/**
 * TODO: refine how defaults are selected
 * @param {Object} selectorOptions
 * @param {String} selectorOptions.containerId
 * @param {Array} [selectorOptions.dateseries]
 * @param {Object} [selectorOptions.dateOptions]
 * @param {Object} [selectorOptions.dateOptions.default]
 * @param {String[]} [selectorOptions.hide] -- ID of selectors to hide
 * @param {String[]} [selectorOptions.show] -- ID of specific selectors to show
 * @param {Function} selectorOptions.onChange -- function to execute when a change is done
 * @param {Object} selectorOptions.customSettings -- eg. statementType
 * @returns
 */
export function activateOptionSelector (selectorOptions) {
  enableConsole(true)
  console.group('activateOptionSelector')
  console.log('activateOptionSelector', selectorOptions)

  if (!setElementHtml(selectorOptions.containerId, htmlOptionSelectorTemplate)) {
    console.groupEnd('activateOptionSelector')
    return
  }

  showElement('optionSelectorContainer')

  makeMenu(selectorOptions, optionSelectorData)

  console.groupEnd('activateOptionSelector')

  return optionSelectorData

  // mOptionsPeriodicity.activate(params)
  // mOptionsPeriodsIncluded.activate(params)

  // // Other Views
  // mOptionsForecast.activateForecastOptions(params)

  // // Show confirmation button
  // // TODO

  // updateSelectors(params)
  // return params
}
