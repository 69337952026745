
import { setElementHtml } from '../../../helpers/dom.js'
import addListeners from './add-listeners.js'
import htmlOptionsJournals from './options-journals.html'

export default function renderOptionsJournals (selectorOptions, optionSelectorData, privateInstance) {
  console.log('renderOptionsJournals', selectorOptions, privateInstance)
  console.log('current value', privateInstance.api.getValue())

  const dom = setElementHtml('optionSelectorContent', htmlOptionsJournals)
  if (!dom) return

  privateInstance.data.htmlElement = dom

  addListeners(privateInstance)
}
