import { loadDisconnectXeroOrg } from '../../../xero/index.js'
import { loadCheckToken } from '../../../xero/organisations-checktoken.js'

export function tokenListeners (orgId) {
  let checkTokenOngoing = false
  const checkTokenElement = document.getElementById('clickCheckToken')
  checkTokenElement.addEventListener('click', async function (clickEvent) {
    if (checkTokenOngoing) return
    checkTokenOngoing = true
    const statusElement = checkTokenElement.querySelector('.status')
    statusElement.innerText = ' > CHECKING'

    const data = await loadCheckToken(orgId)
    console.log('data', data)
    statusElement.innerText = ' > Works? ' + data.tokenWorks.work + ' [Scope=' + data.tokenWorks.tokenScope + ']'

    checkTokenOngoing = false
  })

  let disconnectOngoing = false
  const disconnectElement = document.getElementById('clickDisconnect')
  disconnectElement.addEventListener('click', async function (clickEvent) {
    if (disconnectOngoing) return
    disconnectOngoing = true
    const statusElement = disconnectElement.querySelector('.status')
    statusElement.innerText = ' > Disconnecting'

    const data = await loadDisconnectXeroOrg(orgId)
    console.log('data', data)
    statusElement.innerText = ' > Done? ' + data.response

    disconnectOngoing = false
  })
}
