
/**
 * Set default values
 * @param {*} params
 */
export default function enableFilterDates (selectorOptions, optionSelectorData, privateInstance) {
  console.log('enableFilterDates', selectorOptions, optionSelectorData, privateInstance)

  // Take the Options to the sub module itself
  useOptionSelectorParams(selectorOptions, privateInstance)

  // Take the module values to the parent Selector
  passModuleValuesToOptionSelector(optionSelectorData, privateInstance)

  // Set the callback functions
  privateInstance.callbacks.onChangeInternal = function () {
    passModuleValuesToOptionSelector(optionSelectorData, privateInstance)
  }
  privateInstance.callbacks.onChange = selectorOptions.onChange
  console.log('optionSelectorData', optionSelectorData)
}

/**
 *
 * @param {*} optionSelectorData
 * @param {*} module
 */
function passModuleValuesToOptionSelector (optionSelectorData, privateInstance) {
  optionSelectorData.filterDates = privateInstance.api.getValues()
}

/**
 * Takes the optional defaults given to the OptionSelector and bring them to the module
 * @param {*} selectorOptions
 * @param {*} module
 */
function useOptionSelectorParams (selectorOptions, privateInstance) {
  privateInstance.api.setValues({
    journals: selectorOptions.filterDates?.defaults?.journals,
    records: selectorOptions.filterDates?.defaults?.records
  })
}
