import { callApi } from '../../../helpers/fetch.js'

export async function infosShowAll (orgId) {
  const apiEndpoint = '/api/finlogic/data/:orgId/infos'
  const apiData = await callApi(apiEndpoint, {
    orgId
  })
  console.log(apiData)
  showData(apiData?.orgDataInfos)
}

function showData (data) {
  const parentContainerId = 'organisationDataInfos'

  const containerElement = document.getElementById(parentContainerId)
  containerElement.classList.remove('is-hidden')

  const contentElement = containerElement.querySelector('.content')
  contentElement.innerHTML = ''

  const htmlTexts = []
  const infoElements = document.createElement('div')

  Object.keys(data).forEach(function (dataKey) {
    htmlTexts.push('<b>' + dataKey + ':</b> ' + data[dataKey] + '<br>')
  })

  infoElements.innerHTML = htmlTexts.join('')
  contentElement.append(infoElements)
}
