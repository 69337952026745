
export default function onChange (privateInstance, clicked) {
  console.log('onChange', clicked)

  privateInstance.api.setValue(clicked)
  // console.log('getValue()', privateInstance.api.getValue())

  // Execute callbacks
  if (privateInstance.callbacks?.onChangeInternal) {
    privateInstance.callbacks.onChangeInternal()
  }

  if (privateInstance.callbacks?.onChange) {
    privateInstance.callbacks.onChange(privateInstance.api)
  }
}
