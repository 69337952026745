import { ascending } from 'd3'

export default function makeAccountsRows (context) {
  const entriesMap = new Map()

  context.data.journalsData?.forEach(function (journalEntry) {
    const rowEntry = getMapEntry(entriesMap, journalEntry)

    rowEntry.values.push(journalEntry)
  })
  // console.log('entriesMap', entriesMap)

  const rows = Array.from(entriesMap, function (entry) {
    return entry[1]
  })

  rows.sort(function (r1, r2) {
    const v1 = [r1.chartOfAccount?.statement, r1.chartOfAccount?.code, r1.chartOfAccount?.name].join('')
    const v2 = [r2.chartOfAccount?.statement, r2.chartOfAccount?.code, r2.chartOfAccount?.name].join('')
    return ascending(v1, v2)
  })

  return rows
}

function getMapEntry (entriesMap, inputRecord) {
  const key = inputRecord.journalLine._chartOfAccount

  const entry = entriesMap.get(key)
  if (entry) return entry

  const newEntry = {
    chartOfAccount: inputRecord.journalLine.chartOfAccount,
    values: []
  }

  entriesMap.set(key, newEntry)
  return newEntry
}
