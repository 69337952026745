import dayjs from 'dayjs'
import { formatNumber } from '../../../../helpers/helpers/numbers.js'

export function makeColumnDefs (context) {
  const columnDefs = []

  // Entity column, only when needed
  const entityColumn = {
    headerName: 'Entity',
    valueGetter: function (cellParams) {
      return cellParams.data.entity?.name
    },
    pinned: 'left'
  }
  if (Array.isArray(context.showOrgId)) {
    entityColumn.hide = false
  } else {
    entityColumn.hide = true
  }
  columnDefs.push(entityColumn)

  columnDefs.push({
    headerName: 'Statement',
    valueGetter: function (cellParams) {
      return cellParams.data.chartOfAccount?.statement
    },
    // width: 80,
    pinned: 'left'
  })

  columnDefs.push({
    headerName: 'Type',
    valueGetter: function (cellParams) {
      return cellParams.data.chartOfAccount?.xeroType
    },
    // width: 100,
    pinned: 'left'
  })

  columnDefs.push({
    headerName: 'AccountId',
    valueGetter: function (cellParams) {
      return cellParams.data.chartOfAccount?._id
    },
    // width: 120,
    pinned: 'left'
  })

  columnDefs.push({
    headerName: 'Code',
    valueGetter: function (cellParams) {
      return cellParams.data.chartOfAccount?.code
    },
    // width: 80,
    pinned: 'left'
  })

  columnDefs.push({
    headerName: 'Name',
    valueGetter: function (cellParams) {
      return cellParams.data.chartOfAccount?.name
    },
    // width: 200,
    pinned: 'left'
  })

  columnDefs.push({
    headerName: 'Month',
    valueGetter: function (cellParams) {
      return dayjs(cellParams.data.group?.month).format('YYYY-MM')
    },
    // width: 200,
    pinned: 'left'
  })

  columnDefs.push({
    headerName: 'Amount (Credit positive)',
    valueGetter: function (cellParams) {
      return -1 * (cellParams.data.value || 0)
    },
    valueFormatter: function (cellParams) {
      return formatNumber(cellParams.value, context.viewDecimals)
    },
    type: 'numericColumn'
    // width: 200
  })

  columnDefs.push({
    headerName: 'Currency',
    valueGetter: function (cellParams) {
      return cellParams.data.currency
    }
    // width: 80
  })

  columnDefs.push({
    headerName: 'Tracking1',
    valueGetter: function (cellParams) {
      const tracking1 = cellParams.data.group?.tracking1
      if (!tracking1) return
      return [tracking1.k, tracking1.v].join('=')

      // if (!cellParams.data.trackings) return

      // const trackingChoices = Object.keys(cellParams.data.trackings)
      // return [trackingChoices[0], cellParams.data.trackings[trackingChoices[0]]].join('=')
    }
    // width: 80
  })

  columnDefs.push({
    headerName: 'Tracking2',
    valueGetter: function (cellParams) {
      const tracking1 = cellParams.data.group?.tracking2
      if (!tracking1) return
      return [tracking1.k, tracking1.v].join('=')
    }
    // width: 80
  })

  return columnDefs
}
