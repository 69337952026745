import { ascending } from 'd3'
import { fillString } from '../../../../helpers/templating/string-template.js'

export function makeRows (data) {
  console.log('makeRows', data)

  const rowsMap = new Map()

  data?.journalsValues?.forEach(function (one) {
    const mapEntry = getMapEntry(rowsMap, one)
    mapEntry.journalsValues = one
  })
  data?.tbValues?.forEach(function (one) {
    const mapEntry = getMapEntry(rowsMap, one)
    mapEntry.tbValues = one
  })

  data?.combinedValuesTotal?.forEach(function (one) {
    const mapEntry = getMapEntry(rowsMap, one)
    mapEntry.combinedValuesTotal = one
  })
  data?.combinedValuesJournalsOnly?.forEach(function (one) {
    const mapEntry = getMapEntry(rowsMap, one)
    mapEntry.combinedValuesJournalsOnly = one
  })
  data?.combinedValuesAdjOnly?.forEach(function (one) {
    const mapEntry = getMapEntry(rowsMap, one)
    mapEntry.combinedValuesAdjOnly = one
  })

  console.log(rowsMap)

  const rows = Array.from(rowsMap, function (row) {
    return {
      chartOfAccount: row[1].chartOfAccount,
      month: row[1].month,
      values: row[1]
    }
  })
  console.log(rows)

  // Sort
  rows.sort(function (r1, r2) {
    return ascending(r1.account, r2.account) || ascending(r1.month, r2.month)
  })

  return rows
}

function makeKey (one) {
  const key = fillString(':month :account', {
    account: one._chartOfAccount,
    month: one.month
  })
  // console.log('key', key)

  return key
}

function getMapEntry (rowsMap, one) {
  const key = makeKey(one)

  const existingEntry = rowsMap.get(key)
  if (existingEntry) return existingEntry

  const entry = {
    month: one.month,
    _chartOfAccount: one._chartOfAccount,
    chartOfAccount: one.chartOfAccount,
    // The different values
    journalsValues: null,
    tbValues: null,
    combinedValuesTotal: null,
    combinedValuesJournalsOnly: null,
    combinedValuesAdjOnly: null
  }
  rowsMap.set(key, entry)

  return entry
}
