// App config
import * as configVar from '../../../config/config.js'
import { setElementHtml } from '../../../helpers/dom.js'

import htmlPageTemplate from './index.html'

import '../style.less'
import { activateDropOrders } from './drop-data/activate-drop-orders.js'
import { activateDropSettlements } from './drop-data/activate-drop-settlements.js'
import { setPeriods } from '../utilities/set-periods.js'
import { showCostRecording } from './cost-recording/show-cost-recording.js'
import { choosePlatform } from './ui/choose-platform.js'
import { chooseCountry } from './ui/choose-country.js'
import { showSalesRecording } from './sales-recording/show-sales-recording.js'
import { activateDropProducts } from './drop-products/activate-drop-products.js'
import { activateViews } from './ui/activate-views.js'
import { activateDatetypeSelector } from './ui/activate-datetype-selector.js'
import { activateActions } from './ui/activate-actions.js'
import { showPlatformSettingsTable } from './settings/settings-table/show-table.js'
import { loadFooter } from '../../../modules/footer/footer.js'

export async function showCommercePage () {
  const containerElement = setElementHtml(configVar.contentElementId, htmlPageTemplate)
  if (!containerElement) return

  // Drop Data
  activateDropOrders()
  activateDropSettlements()
  choosePlatform()
  chooseCountry()

  activateDropProducts()

  // Show the settings
  showSalesRecording()
  showCostRecording()

  setPeriods()
  activateDatetypeSelector()

  activateViews()
  activateActions()

  addListener()

  loadFooter()
}

function addListener () {
  document.getElementById('viewSettings').addEventListener('click', function () {
    showPlatformSettingsTable({ all: true })
  })
}
