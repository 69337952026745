// import { createGrid } from 'ag-grid-community'
import { hideElement, showElement } from '../../../helpers/dom.js'
import { optionSelectorData } from '../../../modules/option-selector/data.js'
import { loadDataAggregations } from './load-data.js'
// import { makeColumnDefs } from './make-column-defs.js'
import { appAccount } from '../../../modules/user/account.js'
// import { makeColumns } from './table/make-columns.js'
// import { makeRows } from './table/make-rows.js'

const context = {
  data: {}
}

// let gridApi

/**
 *
 */
export async function updateView () {
  console.log('updateView', optionSelectorData)
  showElement('statementLoadingMessage')
  hideElement('statementContainer')

  // Params in use
  context.showOrgId = optionSelectorData.organisationId || context.showOrgId
  context.currencySymbol = optionSelectorData.currencySymbol || 'XXX'
  context.viewDecimals = optionSelectorData.decimals || 0
  context.dates = optionSelectorData.dates
  context.accountData = appAccount.accountData()
  // context.groupings = ['accounts', 'tracking1', 'tracking2']

  // Load data
  const response = await loadDataAggregations(context)
  console.log('context', context)
  context.data = response

  // const gridOptions = {
  //   rowData: context.data.journalsData,
  //   columnDefs: makeColumnDefs(context),
  //   defaultColDef: {
  //     filter: true,
  //     floatingFilter: true
  //   },
  //   autoSizeStrategy: {
  //     type: 'fitCellContents'
  //   },
  //   context
  // }

  // // Include Top Pinned row with total of amounts

  // const containerId = 'grid'
  // const gridContainerElement = document.getElementById(containerId)

  // if (gridApi) {
  //   gridApi.setGridOption('rowData', context.data.journalsData)
  // } else {
  //   gridApi = createGrid(gridContainerElement, gridOptions)
  // }

  // console.log('gridApi', gridApi)

  // hideElement('statementLoadingMessage')
  // showElement('statementContainer')
}
