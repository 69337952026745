
import htmlTemplateIndex from './index.html'
import { appConfig } from '../../../config/config.js'
import { appUser } from '../../../modules/user/user.js'
import { appAccount } from '../../../modules/user/account.js'
import { appHtmlParser } from '../../../modules/user/html-parser.js'
import { showSelectors } from './show-selectors.js'
import { updateView } from './update-view.js'
import { fillString } from '../../../../helpers/templating/string-template.js'
import { generateRandomString } from '../../../../helpers/helpers/random.js'
import { activateActions } from './activate-actions.js'
/**
 *
 * @returns
 */
export async function showPageJournals () {
  console.log('showPageJournals')

  if (appUser.enforceRestrictedPage({ allowPublic: true })) return

  // Ensure the page content element is available
  const DOMelement = document.getElementById(appConfig.contentElementId)
  if (!DOMelement) return

  const accountData = appAccount.accountData()
  console.log('accountData', accountData)
  document.title = ['Accounting Journals:', accountData.name].join(' ')

  let htmlPageLayout = appHtmlParser.applyRestrictions(htmlTemplateIndex, { accountData })

  const elementId = 'i' + generateRandomString()
  htmlPageLayout = fillString(htmlPageLayout, { elementId })

  // Inject the page in the app container
  DOMelement.innerHTML = htmlPageLayout

  document.getElementById('app-content').classList.add('position-absolute')
  document.getElementById('app-content').classList.add('top-to-bottom')

  showSelectors(updateView)

  activateActions(document.getElementById(elementId))
}
