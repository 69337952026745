import { ascending, descending } from 'd3'
import dayjs from 'dayjs'
import { formatNumber } from '../../../../helpers/helpers/numbers.js'
import { makeMonthSeries } from './month-series.js'
import { valueMaker } from './value-maker.js'
import { valuePinnedMaker } from './value-pinned-maker.js'

export function makeColumnDefs (context) {
  const columnDefs = []

  // Chart of Account Code
  columnDefs.push({
    headerName: 'Code',
    valueGetter: function (cellParams) {
      const code = cellParams.data.chartOfAccount?.code
      if (!code) return

      return code
    },
    width: 100,
    pinned: 'left'
  })

  // Chart of Account Name
  columnDefs.push({
    headerName: 'Account',
    valueGetter: function (cellParams) {
      if (cellParams.node.rowPinned) {
        return cellParams.data.name
      }

      const name = cellParams.data.chartOfAccount?.name
      if (!name) return

      return name
    },
    width: 200,
    pinned: 'left'
  })

  // Add the months columns
  columnDefs.push(...makeMonthsColumns(context))

  return columnDefs
}

/**
 *
 * @param {*} context
 * @returns
 */
function makeMonthsColumns (context) {
  console.log('makeMonthsColumns', context)

  const monthsArray = makeMonthSeries(context)

  monthsArray?.sort(function (m1, m2) {
    if (context.timeDirection === 'descending') {
      return descending(m1, m2)
    } else {
      return ascending(m1, m2)
    }
  })

  const monthColDefs = []
  monthsArray?.forEach(function (oneMonth) {
    const colDef = {
      headerName: dayjs(oneMonth).format('MMM YYYY'),
      valueGetter: function (cellParams) {
        const targetMonth = cellParams.colDef.context?.month

        if (cellParams.node.rowPinned) {
          const cellValue = valuePinnedMaker(cellParams, targetMonth, context)
          return cellValue
        }

        const cellValue = valueMaker(cellParams.data, targetMonth, context)
        return cellValue
      },
      valueFormatter: function (cellParams) {
        if (!cellParams.value) return

        return formatNumber(cellParams.value, context.viewDecimals)
      },
      context: {
        month: oneMonth
      },
      type: 'numericColumn',
      cellClass: function (cellParams) {
        const classes = ['right', 'number']
        if (!cellParams.value) {
          classes.push('zero')
        }
        return classes
      },
      width: 130

    }
    monthColDefs.push(colDef)
  })

  return monthColDefs
}
