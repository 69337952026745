import onChange from './on-change.js'

export default function addListeners (privateInstance) {
  const htmlElement = privateInstance.data?.htmlElement

  const inputElements = htmlElement.querySelectorAll('.button')

  inputElements.forEach(function (element) {
    element.addEventListener('click', function (event) {
      const clicked = event.target.getAttribute('data-value')
      onChange(privateInstance, clicked)
    })
  })
}
