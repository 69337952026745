
// App config
import * as configVar from '../../../config/config.js'
import { setElementHtml } from '../../../helpers/dom.js'
import { callApi } from '../../../helpers/fetch.js'

// Dependencies
import * as mUser from '../../../modules/user/user.js'

// Page Module
import htmlIndex from './source.html'

/**
 *
 * @returns
 */
export async function showSourceInfos (xeroSourceId) {
  console.log('showSourceInfos()', xeroSourceId)

  if (mUser.enforceRestrictedPage({ allowNoAccount: true })) return

  // Ensure the page content element is available
  const DOMelement = document.getElementById(configVar.contentElementId)
  if (!DOMelement) return

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')
  contentElement.innerHTML = htmlIndex

  // Inject the page in the app container
  DOMelement.innerHTML = ''
  DOMelement.append(contentElement)

  await loadSourceData(xeroSourceId)
  await loadJournalsOfSourceData(xeroSourceId)
}

async function loadSourceData (xeroSourceId) {
  const sourceData = await callApi('/api/finlogic/source-data/:xeroSourceId', { xeroSourceId })

  setElementHtml('sourceDetails', JSON.stringify(sourceData))
  return sourceData
}

async function loadJournalsOfSourceData (xeroSourceId) {
  const journalsOfSourceResults = await callApi('/api/finlogic/source-data/:xeroSourceId/journals', { xeroSourceId })

  setElementHtml('journalsOfSource', JSON.stringify(journalsOfSourceResults))

  console.log('journalsOfSourceResults', journalsOfSourceResults)

  journalsOfSourceResults?.journalsOfSource?.forEach(async function (journal) {
    console.log('journal', journal)
    const xeroJournalId = journal.journalId
    const orgId = journal._xeroOrganisation

    await callApi('/api/xero/sync/call-xero-for-journal/:xeroJournalId/orgId/:orgId', { xeroJournalId, orgId })
  })

  return journalsOfSourceResults.journalsOfSource
}
