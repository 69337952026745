
// App config
import * as configVar from '../../../config/config.js'

// Dependencies
import * as mUser from '../../../modules/user/user.js'

import './style.less'

// Page Module
import htmlIndex from './index.html'
import { callApi } from '../../../helpers/fetch.js'
import { showCurrentTimestamp } from '../utilities/timestamp.js'
import { showSyncsAgGrid } from './show-syncs-aggrid.js'

/**
 *
 * @returns
 */
export async function showPageSyncs (orgId) {
  console.log('showPageSyncs')

  if (mUser.enforceRestrictedPage({ allowNoAccount: true })) return

  // Ensure the page content element is available
  const DOMelement = document.getElementById(configVar.contentElementId)
  if (!DOMelement) return

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')
  contentElement.innerHTML = htmlIndex

  // Inject the page in the app container
  DOMelement.innerHTML = ''
  DOMelement.append(contentElement)

  showCurrentTimestamp('currentTimestamp')
  showListOfSyncs(orgId)
}

async function showListOfSyncs (orgId) {
  const listOfSyncs = await loadListOfSyncs(orgId)
  console.log('listOfSyncs', listOfSyncs)
  showSyncsAgGrid(listOfSyncs)
}

/**
 */
async function loadListOfSyncs (orgId) {
  // Avoid undefined: allows to have all orgs
  if (!orgId) orgId = ''

  const apiUrl = '/api/xero/sync/info/list/:orgId'
  const data = await callApi(apiUrl, {
    orgId
  })
  return data.syncList
}
