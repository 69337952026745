import { setElementHtml } from '../../../helpers/dom.js'

// Module
import htmlTemplateSelectorOthers from './options-others.html'
import htmlTemplateOneDecimalButton from './options-others-button.html'
import { optionSelectorData } from '../data.js'
import { executeOnChange } from '../onchange.js'
import { fillString } from '../../../../helpers/templating/string-template.js'

export function activateDecimals (params) {
  console.log('activateDecimals', params)
  showSelector()
  listeners(params)
}

function listeners (params) {
  const selectorElement = document.getElementById('decimalsButtons')
  selectorElement.addEventListener('click', function (clickEvent) {
    console.log('decimals click')
    const target = clickEvent.target.closest('button')
    if (!target) return
    console.log('target', target)

    const targetDecimals = target.getAttribute('d-decimals')
    console.log(targetDecimals)

    optionSelectorData.decimals = +targetDecimals

    executeOnChange(params)
  })
}

/**
 *
 * @returns
 */
function showSelector () {
  const htmlSelector = htmlTemplateSelectorOthers

  const dom = setElementHtml('optionSelectorContent', htmlSelector)
  if (!dom) return

  const buttonsHtml = []

  const decimalsOptions = [{
    label: '1,000',
    decimals: 0
  }, {
    label: '1,000.00',
    decimals: 2
  }]

  decimalsOptions.forEach(function (oneDecimalOption) {
    const htmlButton = fillString(htmlTemplateOneDecimalButton, {
      numberDecimals: oneDecimalOption.decimals,
      label: oneDecimalOption.label
    })

    buttonsHtml.push(htmlButton)
  })

  setElementHtml('decimalsButtons', buttonsHtml.join(''))
}
