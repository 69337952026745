import { getSheet } from './get-sheet.js'
import { readXLSXfile } from './read-xslx-file.js'
import { updateSheetRange } from './update-range.js'

export async function getFileSheet (file, options) {
  const workbook = await readXLSXfile(file)
  // console.log('workbook', workbook)
  console.log('options', options)
  console.log('sheets available:', workbook.SheetNames)

  const targetSheetName = sheetToUse(options, workbook)
  alertUnexpectedSheet(options, workbook, targetSheetName)
  if (!targetSheetName) return

  const worksheet = getSheet(workbook, targetSheetName)
  updateSheetRange(worksheet)
  // console.log('worksheet', worksheet)

  return worksheet
}

function sheetToUse (options, workbook) {
  if (workbook.SheetNames.length === 1) {
    return workbook.SheetNames[0]
  }

  if (workbook.SheetNames.includes(options?.sheetName)) {
    return options?.sheetName
  }

  return workbook.SheetNames[0]
}

function alertUnexpectedSheet (options, workbook, targetSheetName) {
  if (options?.sheetName && !workbook.SheetNames.includes(options?.sheetName)) {
    console.warn('target sheet does not exist:', options?.sheetName, '; in ', workbook.SheetNames)
  }

  if (options?.sheetName !== targetSheetName) {
    console.warn('using', targetSheetName, 'vs.', options?.sheetName)
  }
}
