
import { appConfig } from '../../../config/config.js'
import { callApi } from '../../../helpers/fetch.js'
import { appUser } from '../../../modules/user/user.js'
import htmlIndex from './index.html'
import { renderSync } from './render-sync.js'

export async function showPageOneSync (syncId) {
  console.log('showPageOneSync', syncId)

  if (appUser.enforceRestrictedPage({ allowNoAccount: true })) return

  // Ensure the page content element is available
  const DOMelement = document.getElementById(appConfig.contentElementId)
  if (!DOMelement) return

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')
  contentElement.innerHTML = htmlIndex

  // Inject the page in the app container
  DOMelement.innerHTML = ''
  DOMelement.append(contentElement)

  const syncData = await loadSync(syncId)
  renderSync(syncData)
}

/**
 *
 * @param {*} syncId
 * @returns
 */
async function loadSync (syncId) {
  console.log('loadSync', syncId)
  if (!syncId) return

  const apiUrl = '/api/xero/sync/info/details/:syncId'
  const data = await callApi(apiUrl, {
    syncId
  })
  console.log('data', data)
  return data.syncData
}
