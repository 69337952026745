import { disableSupportAccess, enableSupportAccess } from '../../utilities/support-invite.js'

export function showOrgAccounts (byUsersAndAccounts) {
  const containerElement = document.getElementById('organisationAccounts')
  containerElement.classList.remove('is-hidden')

  const contentElement = containerElement.querySelector('.content')
  contentElement.innerHTML = ''

  Object.values(byUsersAndAccounts.byAccount).forEach(function (oneAccount) {
    const accountElement = document.createElement('div')
    accountElement.classList.add('content')

    const nameElement = document.createElement('span')
    nameElement.classList.add('bold')
    nameElement.innerText = oneAccount.account.name + '   '
    accountElement.append(nameElement)

    if (oneAccount.account.isPublic) {
      const isPublicTag = document.createElement('span')
      isPublicTag.innerText = 'isPublic'
      isPublicTag.classList.add('tag', 'is-warning')
      accountElement.append(isPublicTag)
    }

    const creationDate = document.createElement('span')
    creationDate.innerText = (new Date(oneAccount.account.createdAt)).toString()
    creationDate.classList.add('tag')
    accountElement.append(creationDate)

    const accountLink = document.createElement('a')
    accountLink.setAttribute('href', '/app/a/:accountId'.replace(':accountId', oneAccount.account._id))
    accountLink.setAttribute('target', '_blank')
    accountLink.classList.add('tag', 'is-primary')
    accountLink.innerText = 'Open zenflow account'
    accountElement.append(accountLink)

    const accountPnLLink = document.createElement('a')
    accountPnLLink.setAttribute('href', '/app/a/:accountId/pnl/all'.replace(':accountId', oneAccount.account._id))
    accountPnLLink.setAttribute('target', '_blank')
    accountPnLLink.classList.add('tag', 'is-primary')
    accountPnLLink.innerText = 'Open P&L'
    accountElement.append(accountPnLLink)

    const accountUsersElement = document.createElement('div')

    let isSupportEnabled = false

    oneAccount.users.forEach(function (oneUser) {
      const userElement = document.createElement('div')
      userElement.innerHTML = '> ' + oneUser.user?.name + ' (' + oneUser.user?.email + ')' + ' [' + oneUser.rights?.join(', ') + ']' + (oneUser.isSupport ? ' <b>SUPPORT</b>' : '')

      if (oneUser.isSupport) isSupportEnabled = oneUser.inviteId
      accountUsersElement.append(userElement)
    })

    if (!isSupportEnabled) enableSupportAccess(accountUsersElement, oneAccount.account._id)
    if (isSupportEnabled) disableSupportAccess(accountUsersElement, isSupportEnabled)

    accountElement.append(accountUsersElement)
    contentElement.append(accountElement)
  })
}
