import { makeDateSerie } from '../../../../helpers/dates/make-date-serie.js'
import { activateOptionSelector } from '../../../modules/option-selector/index.js'
import { appAccount } from '../../../modules/user/account.js'

export function showSelectors (changeAction) {
  console.log('showSelectors')

  const optionSelectorShow = []

  const accountData = appAccount.accountData()
  const dateseries = makeDateSerie(accountData.firstDate)
  const currencies = accountData?.currencies

  const selectorOptions = {
    containerId: 'optionSelector',
    show: optionSelectorShow,
    hide: ['currencies', 'dates'],
    onChange: changeAction,
    dateseries,
    customSettings: {
      organisations: mapOrganisations(),
      orgOptions: {
        allowConso: false,
        allowMultiple: false
        // defaultIndex: 0
      },
      currencies
    }
  }
  console.log(selectorOptions)

  const optionSelectorParams = activateOptionSelector(selectorOptions)
  console.log('optionSelectorParams', optionSelectorParams)
  return optionSelectorParams
}

function mapOrganisations () {
  const accountData = appAccount.accountData()
  // console.log('accountData', accountData)

  const organisations = accountData?._accountOrganisations?.map(function (accountOrganisation) {
    return accountOrganisation._xeroOrganisation
  })
  return organisations
}
