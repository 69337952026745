export function groupUsersAndAccounts (organisationAccess) {
  const byUser = {}
  const byAccount = {}

  function storeByUser (oneUserAccount) {
    if (!oneUserAccount._user) return

    byUser[oneUserAccount._user._id] = byUser[oneUserAccount._user._id] || {
      user: oneUserAccount._user,
      accounts: []
    }

    byUser[oneUserAccount._user._id].accounts.push({
      inviteId: oneUserAccount._id,
      isSupport: oneUserAccount.isSupport,
      rights: oneUserAccount.rights,
      account: oneUserAccount._account
    })
  }

  function storeByAccount (oneUserAccount) {
    if (!oneUserAccount._account) return

    byAccount[oneUserAccount._account._id] = byAccount[oneUserAccount._account._id] || {
      account: oneUserAccount._account,
      users: []
    }

    byAccount[oneUserAccount._account._id].users.push({
      inviteId: oneUserAccount._id,
      isSupport: oneUserAccount.isSupport,
      rights: oneUserAccount.rights,
      user: oneUserAccount._user
    })
  }

  organisationAccess.allUserAccounts.forEach(function (oneAccount) {
    oneAccount.forEach(function (oneUserAccount) {
      storeByUser(oneUserAccount)
      storeByAccount(oneUserAccount)
    })
  })

  return { byUser, byAccount }
}
