
// App config
import * as configVar from '../../config/config.js'

import * as mAccount from '../../modules/user/account.js'

import { enforceRestrictedPage } from '../../modules/user/user.js'

import { hideElement, setElementHtml } from '../../helpers/dom.js'
import { createContainer, setPageTitle } from '../financials/helpers.js'

/**
 * This page allows to page settings to Chrome extensions
 * ShortCodes to entity Name info
 * Base Currency for entities
 * FX Rates for entities
 * @param {Object} params
 * @returns
 */
export async function showPageLinkExtensionChrome (params) {
  console.log('showPageLinkExtensionChrome')

  if (enforceRestrictedPage({ allowPublic: true })) return

  const DOMelement = setElementHtml(configVar.contentElementId, '')
  if (!DOMelement) return

  DOMelement.classList.add('position-absolute', 'top-to-bottom')

  const title = 'Link Chrome Extension'
  const contentElement = createContainer(title)
  DOMelement.append(contentElement)

  //
  const accountData = mAccount.accountData()
  console.log('accountData', accountData)

  setPageTitle(accountData, title)

  showData(accountData)

  hideElement('statementLoadingMessage')
}

function makeSelectEntitiesData (accountData) {
  const selectEntitiesData = accountData._accountOrganisations?.map(function (accountOrganisation) {
    return {
      shortCode: accountOrganisation._xeroOrganisation?.details.shortCode,
      name: accountOrganisation._xeroOrganisation?.name,
      baseCurrency: accountOrganisation._xeroOrganisation?.details.baseCurrency,
      legalName: accountOrganisation._xeroOrganisation?.details.legalName
    }
  })
  return selectEntitiesData
}

function showData (accountData) {
  const data = {
    entities: makeSelectEntitiesData(accountData),
    fxRates: {
      // Ideally, it would take the rate from the DB, based on 'details.currencyCode'} on chart of accounts
      SGD: 1,
      USD: 0.75,
      EUR: 0.7,
      GBP: 0.6,
      HKD: 6,
      IDR: 11870,
      THB: 26,
      VND: 18900,
      PHP: 43,
      INR: 61.8,
      LKR: 230,
      MYR: 3.48
    }
  }

  setElementHtml('statementContainer', JSON.stringify(data))
}
