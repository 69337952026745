import { callApi } from '../../../helpers/fetch.js'

/**
 * filterDates
 *  journalDates=[from,to]
 *
 * @param {*} context
 * @param {String} context.showOrgId
 * @returns
 */
export async function loadData (context) {
  console.log('loadData', context)
  const orgId = context.showOrgId

  //
  const journalsValues = callApi('/api/finlogic/data/:orgId/view-journals-account-month', { orgId })
  const tbValues = callApi('/api/finlogic/data/:orgId/tb-values-account-month', { orgId })

  const combinedValuesTotal = callApi('/api/finlogic/data/:orgId/combined-values-account-month', { orgId })
  const combinedValuesJournalsOnly = callApi('/api/finlogic/data/:orgId/combined-values-account-month/journal', { orgId })
  const combinedValuesAdjOnly = callApi('/api/finlogic/data/:orgId/combined-values-account-month/adj', { orgId })

  const results = await Promise.all([
    journalsValues,
    tbValues,
    combinedValuesTotal,
    combinedValuesJournalsOnly,
    combinedValuesAdjOnly
  ])

  context.data = {
    journalsValues: results[0]?.data,
    tbValues: results[1]?.data,

    combinedValuesTotal: results[2]?.data,
    combinedValuesJournalsOnly: results[3]?.data,
    combinedValuesAdjOnly: results[4]?.data
  }
}
