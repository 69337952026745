import { generateRandomString } from '../../../../../helpers/helpers/random.js'
import renderHtmlElement from './render.js'

export default function init (privateInstance, options) {
  console.log('init', options)
  if (!options) return

  if (!options.containerId) {
    console.error('containerId is required')
  }
  privateInstance.data.containerId = options.containerId

  // Make an ID for the element instance
  const selectorId = 'e' + generateRandomString()
  privateInstance.data.selectorId = selectorId

  // Consider the Options Set values
  privateInstance.api.setLabels(options.labels)
  privateInstance.api.setValues(options.values?.from, options.values?.to)

  // Render the HTML
  renderHtmlElement(privateInstance)

  // Set the callbacks
  privateInstance.data.callbacks.onChange = options.onChange
}
