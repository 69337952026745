import dayjs from 'dayjs'
import { fillString } from '../../../../helpers/templating/string-template.js'
import { setElementHtml } from '../../../helpers/dom.js'

export function showCurrentTimestamp (elementId) {
  const currentTimeTemplate = 'UTC: <b>:utcTime</b> | Local: <b>:localTime</b>'
  const output = fillString(currentTimeTemplate, {
    utcTime: (new Date()).toJSON(),
    localTime: dayjs().format('YYYY-MM-DD HH:mm:ss')
  })

  setElementHtml(elementId, output)
}
