import * as helperString from '../../../helpers/helpers/string.js'
import { fillString } from '../../../helpers/templating/string-template.js'

/**
 * Hide content
 * Classes which are managed:
 * :forPrivateAccount
 * :forPublicAccount
 * :needRights-canManage
 * @param {String} htmlContent
 * @param {Object} params
 * @param {Object} [params.accountData]
 * @param {Object} [params.userData]
 * @returns {String} updated HTML string
 */
export function applyRestrictions (htmlContent, params) {
  if (!htmlContent) return

  const { userData, accountData } = params

  htmlContent = applyBrowserRelatedClass(htmlContent)
  htmlContent = applyUserRelatedClass(htmlContent, userData)
  htmlContent = applyAccountRelatedClass(htmlContent, accountData)

  return htmlContent
}

export function applyPreviewClass (htmlContent, userData) {
  const isUserAllowed = userData?.email?.includes('florian')
  const hasUserEnabled = userData.isPreviewEnabled

  if (!isUserAllowed || !hasUserEnabled) {
    console.log('hide preview')
    // Admin demo menu
    htmlContent = htmlContent.replaceAll(':forPreviewMenu', 'is-hidden')
  } else {
    htmlContent = htmlContent.replaceAll(':forPreviewMenu', '')
  }
  return htmlContent
}

function applyBrowserRelatedClass (htmlContent) {
  const toggleUserClientPosition = localStorage.getItem('featurePublicAccounts')
  if (helperString.toBoolean(toggleUserClientPosition) === true) {
    htmlContent = htmlContent.replaceAll(':forFeaturePublicAccount', '')
  } else {
    htmlContent = htmlContent.replaceAll(':forFeaturePublicAccount', 'is-hidden')
  }
  return htmlContent
}

function applyUserRelatedClass (htmlContent, userData) {
  if (!userData) {
    htmlContent = htmlContent.replaceAll(':forLoggedInUser', 'is-hidden')
  }

  return htmlContent
}

function applyAccountRelatedClass (htmlContent, accountData) {
  if (!htmlContent) return htmlContent

  if (!accountData) {
    htmlContent = htmlContent.replaceAll(':forAccountContent', 'is-hidden')
    return htmlContent
  }

  htmlContent = htmlContent.replaceAll(':forNoAccountContent', 'is-hidden')

  //
  htmlContent = htmlContent.replaceAll(':isPublic', accountData?.isPublic)

  // Limit visibility based on account isPublic
  if (accountData?.isPublic === true) {
    htmlContent = htmlContent.replaceAll(':forPrivateAccount', 'is-hidden')
  } else {
    htmlContent = htmlContent.replaceAll(':forPublicAccount', 'is-hidden')
  }

  if (!accountData.myRights?.includes('canManage')) {
    htmlContent = htmlContent.replaceAll(':needRights-canManage', 'is-hidden')
  }

  if (!accountData.myRights?.includes('canView')) {
    htmlContent = htmlContent.replaceAll(':needRights-canView', 'is-hidden')
  }

  return htmlContent
}

/**
 *
 * @param {*} htmlContent
 * @param {*} accountData
 * @returns
 */
export function applyAccountData (htmlContent, accountData) {
  if (!htmlContent) return

  return fillString(htmlContent, {
    accountName: accountData?.name,
    accountId: (accountData?._id || accountData?.id)
  })
}

export const appHtmlParser = {
  applyRestrictions,
  applyPreviewClass,
  applyAccountData
}
