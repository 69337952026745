
export default function onChange (privateInstance) {
  console.log('onChange', privateInstance)
  const htmlElement = privateInstance.data?.htmlElement

  const newFrom = htmlElement?.querySelector('[d-id="value-from"]').value
  const newTo = htmlElement?.querySelector('[d-id="value-to"]').value
  privateInstance.api.setValues(newFrom, newTo)

  // Execute callbacks
  if (privateInstance.data?.callbacks?.onChange) {
    privateInstance.data?.callbacks?.onChange(privateInstance.api)
  }
}
