import enableFilterDates from './enable.js'
import renderFilterDates from './render-filter-dates.js'

export default function filterDatesModule () {
  const data = {
    journals: {
      from: null,
      to: null
    },
    records: {
      from: null,
      to: null
    }
  }

  function setValues (values) {
    if (values.journals?.from) data.journals.from = values.journals?.from
    if (values.journals?.to) data.journals.to = values.journals?.to

    if (values.records?.from) data.records.from = values.records?.from
    if (values.records?.to) data.records.to = values.records?.to
  }

  function getValues () {
    return data
  }

  function enable (options, optionSelectorData) {
    return enableFilterDates(options, optionSelectorData, privateInstance)
  }

  function openModule (options, optionSelectorData) {
    return renderFilterDates(options, optionSelectorData, privateInstance)
  }

  const publicAPI = {
    enable,
    openModule,
    getValues,
    setValues
  }

  const privateInstance = {
    renders: {
      journalsInstance: null,
      recordsInstance: null
    },
    callbacks: {},
    api: publicAPI
  }

  return publicAPI
}
