
export default function onRangeChanged (instanceAPI, filterDatesInstance) {
  console.log('onRangeChanged')
  console.log('instanceAPI', instanceAPI)
  const newValues = instanceAPI.getValues()
  console.log('newValues', newValues, instanceAPI.getContainerId())

  const propertyTargets = {
    optionFilterDatesJournals: 'journals',
    optionFilterDatesRecords: 'records'
  }

  const targetRange = propertyTargets[instanceAPI.getContainerId()]
  if (!targetRange) return

  const newValuesToSet = {
    [targetRange]: {
      from: newValues?.from,
      to: newValues?.to
    }
  }
  filterDatesInstance.api.setValues(newValuesToSet)

  console.log('filterDatesInstance.getValues()', filterDatesInstance.api.getValues())

  // Execute callbacks
  if (filterDatesInstance.callbacks?.onChangeInternal) {
    filterDatesInstance.callbacks.onChangeInternal()
  }

  if (filterDatesInstance.callbacks?.onChange) {
    filterDatesInstance.callbacks.onChange(filterDatesInstance.api)
  }
}
