import { descending } from 'd3'
import { accessObjectPropertyValue } from '../../../../../helpers/data/objects.js'

export function makeRowData (listOfSyncs) {
  // const rows = []
  // return rows

  console.log('makeRows', listOfSyncs)
  // const sortingProperty = 'createdAt'
  // const sortingProperty = 'updatedAt'
  const sortingProperty = 'scheduledAt'
  listOfSyncs.sort(function (r1, r2) {
    return descending(accessObjectPropertyValue(sortingProperty, r1), accessObjectPropertyValue(sortingProperty, r2))
  })
  return listOfSyncs
}
