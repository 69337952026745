
// Dependencies
import { fillString } from '../../../helpers/templating/string-template.js'
import { setElementHtml } from '../../helpers/dom.js'
import { callApi } from '../../helpers/fetch.js'
import * as mIntercom from '../intercom/intercom.js'

// Module
import htmlTemplate from './footer.html'

export async function loadFooter () {
  setElementHtml('footer', htmlTemplate)

  const appStatus = await loadAppStatus()

  let html = 'v:appVersion <span class=":hideIfProd">& env=:env</span>'
  html = fillString(html, {
    appVersion: appStatus.appVersion,
    env: appStatus.env,
    hideIfProd: (appStatus.env === 'production') ? 'is-hidden' : ''
  })
  setElementHtml('app-status', html)

  mIntercom.loadIntercom(appStatus.intercomAppId)
}

async function loadAppStatus () {
  const apiUrl = '/api/status'
  const data = await callApi(apiUrl)

  localStorage.setItem('appStatus', JSON.stringify(data))
  return data
}
