
import { setElementHtml } from '../../../helpers/dom.js'
import htmlFilterDates from './filter-dates.html'
import dateRangeSelector from './date-range/index.js'
import onRangeChanged from './on-range-changed.js'

export default function renderFilterDates (selectorOptions, optionSelectorData, filterDatesInstance) {
  console.log('renderFilterDates', selectorOptions, filterDatesInstance)
  console.log('current values', filterDatesInstance.api.getValues())

  const dom = setElementHtml('optionSelectorContent', htmlFilterDates)
  if (!dom) return

  console.log('optionSelectorData', optionSelectorData)

  const onSubRangeChanged = function (instanceAPI) {
    onRangeChanged(instanceAPI, filterDatesInstance)
  }

  if (filterDatesInstance.renders.journalsInstance) {
    filterDatesInstance.renders.journalsInstance.render()
  } else {
    const dateRangeJournals = dateRangeSelector({
      containerId: 'optionFilterDatesJournals',
      labels: { main: 'Journal Date:' },
      values: filterDatesInstance.api.getValues().journals,
      onChange: onSubRangeChanged
    })
    filterDatesInstance.renders.journalsInstance = dateRangeJournals
  }

  if (filterDatesInstance.renders.recordsInstance) {
    filterDatesInstance.renders.recordsInstance.render()
  } else {
    const dateRangeRecords = dateRangeSelector({
      containerId: 'optionFilterDatesRecords',
      labels: { main: 'Recording Date:' },
      values: filterDatesInstance.api.getValues().records,
      onChange: onSubRangeChanged
    })
    filterDatesInstance.renders.recordsInstance = dateRangeRecords
  }

  console.log('optionSelectorData', optionSelectorData)
}
