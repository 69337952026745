
// App config
import * as configVar from '../../config/config.js'

// Dependencies
import * as mUser from '../../modules/user/user.js'
import * as mAccount from '../../modules/user/account.js'

// Page Module
import * as mData from './data.js'
import { executeShowStatements } from './executor.js'
import * as mGoogleSheet from './google-sheet.js'
import { hideElement, setElementHtml } from '../../helpers/dom.js'
import { callApi } from '../../helpers/fetch.js'
import { createContainer, setOptionSelector, setPageTitle } from './helpers.js'
import { updateSelectors } from '../../modules/option-selector/update-selector.js'

/**
 *
 * @returns
 */
export async function show (params) {
  console.log('module show financials', params)
  const { accountId, orgId, statementType } = params
  mData.db.options.statementType = statementType

  if (mUser.enforceRestrictedPage({ allowPublic: true })) return

  const accountData = mAccount.accountData()
  console.log('accountData', accountData)

  const contentElement = createContainer()

  // Inject the page in the app container
  const DOMelement = setElementHtml(configVar.contentElementId, '')
  if (!DOMelement) return

  DOMelement.classList.add('position-absolute', 'top-to-bottom')
  DOMelement.append(contentElement)
  //

  setPageTitle(accountData)

  await loadFinancials({ accountId, orgId })
  hideElement('statementLoadingMessage')

  mGoogleSheet.listener({ orgId })

  const optionSelectorParams = setOptionSelector(executeShowStatements)
  executeShowStatements()
  updateSelectors(optionSelectorParams)
}

async function loadFinancials (params) {
  console.log('loadFinancials', params)
  const { accountId, orgId } = params

  const apiUrl = '/api/xero/a/:accountId/o/:orgId/financials'
  const data = await callApi(apiUrl, {
    accountId,
    orgId
  })
  console.log('showFinancials data', data)
  mData.db.data = data
  return true
}
