import { valueMaker } from './value-maker.js'

export function valuePinnedMaker (cellParams, targetMonth, context) {
  // console.log('valuePinnedMaker', cellParams, targetMonth)
  let totalValue = 0

  cellParams.api.forEachNodeAfterFilterAndSort(function (rowNode) {
    // console.log('rowNode', rowNode)
    const cellValue = valueMaker(rowNode.data, targetMonth, context)
    totalValue += cellValue
  })

  return totalValue
}
