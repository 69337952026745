import htmlIntro from './sync-details.html'

import dayjs from 'dayjs'
import { ascending } from 'd3'

import { fillString } from '../../../../helpers/templating/string-template.js'
import { setElementHtml } from '../../../helpers/dom.js'
import { timeDuration } from '../../../../helpers/helpers/time.js'

/**
 *
 * @param {Object} syncData
 */
export function renderSync (syncData) {
  console.log('renderSync', syncData)

  const moduleElement = renderSyncIntro(syncData)
  renderModules(moduleElement, syncData)
}

/**
 *
 * @param {*} syncData
 * @returns
 */
function renderSyncIntro (syncData) {
  const readyHtml = fillString(htmlIntro, {
    orgName: syncData._xeroOrganisation?.details?.name,
    orgId: syncData._xeroOrganisation?._id,
    status: syncData.status,
    totalApiCalls: syncData.totalApiCalls,
    scheduledTime: syncData.scheduledTime ? dayjs(syncData.scheduledTime).format('YYYY-MM-DD @ HH:mm:ss') : '-',
    startedAt: syncData.startedAt ? dayjs(syncData.startedAt).format('YYYY-MM-DD @ HH:mm:ss') : '-',
    endedAt: syncData.endedAt ? dayjs(syncData.endedAt).format('YYYY-MM-DD @ HH:mm:ss') : '-',
    totalTime: timeDuration(syncData.startedAt, syncData.endedAt)?.timeText,
    origin: syncData.origin,
    createdAt: syncData.createdAt ? dayjs(syncData.createdAt).format('YYYY-MM-DD @ HH:mm:ss') : '-',
    updatedAt: syncData.updatedAt ? dayjs(syncData.updatedAt).format('YYYY-MM-DD @ HH:mm:ss') : '-',
    numberTokens: syncData.details?.numberOfTokens,
    hasError: syncData.hasError
  })

  const moduleElement = setElementHtml('syncDetails', readyHtml)
  return moduleElement
}

/**
 *
 * @param {*} moduleElement
 * @param {*} syncData
 * @returns
 */
function renderModules (moduleElement, syncData) {
  const moduleDetailsTable = moduleElement.querySelector('#syncModuleDetails')
  if (!moduleDetailsTable) return

  // Turn to Array
  const moduleDetailsArray = Object.entries(syncData.details?.module)?.map(function (oneModule) {
    return {
      moduleName: oneModule[0],
      info: oneModule[1]
    }
  })

  // Sort chronologically
  moduleDetailsArray?.sort(function (m1, m2) {
    return ascending(m1.info?.startedAt, m2.info?.startedAt)
  })

  // Render
  moduleDetailsArray?.forEach(function (moduleData) {
    const moduleHtml = makeModuleRow(moduleData)
    moduleDetailsTable.insertAdjacentHTML('beforeend', moduleHtml)
  })
}

/**
 *
 * @param {*} moduleData
 * @returns
 */
function makeModuleRow (moduleData) {
  const moduleTemplate = `
      <tr>
        <td>:moduleName</td>
        <td>:startedAt</td>
        <td>:endedAt</td>
        <td>:duration</td>
        <td>:numberAPIcalls</td>
        <td>:numberLoops</td>
        <td>:receivedFromApi</td>
        <td>:xeroToken</td>
        <td>:otherInfo</td>
        <td>:latestStep</td>
        <td>:errorInfo</td>
      </tr>
    `

  const startedAt = dayjs(moduleData?.info?.startedAt).format('YYYY-MM-DD @ HH:mm:ss')

  let endedAt
  if (moduleData?.info?.endedAt) {
    endedAt = dayjs(moduleData?.info?.endedAt).format('YYYY-MM-DD @ HH:mm:ss')
  } else {
    endedAt = '-'
  }

  const moduleHtml = fillString(moduleTemplate, {
    moduleName: moduleData.moduleName,
    startedAt,
    endedAt,
    duration: computeDuration(moduleData),
    numberAPIcalls: moduleData?.info?.apiCalls || '-',
    numberLoops: moduleData?.info?.loops || '-',
    receivedFromApi: Number.isFinite(moduleData?.info?.receivedFromApi) ? moduleData?.info?.receivedFromApi : '-',
    xeroToken: moduleData?.info?.xeroTokenOk || 'n/a',
    otherInfo: showOtherInfos(moduleData),
    latestStep: moduleData?.info?.currentStep || 'n/a',
    errorInfo: showError(moduleData?.info?.error)
  })

  return moduleHtml
}

function showOtherInfos (moduleData) {
  const otherInfos = []

  if (moduleData?.info?.startingOffset !== undefined) {
    otherInfos.push('starting Offset=' + moduleData?.info?.startingOffset)
  }

  if (moduleData?.info?.firstMonth !== undefined) {
    otherInfos.push('firstMonth=' + moduleData?.info?.firstMonth)
  }

  if (moduleData?.info?.firstMonth !== undefined) {
    otherInfos.push('expectedLoops=' + moduleData?.info?.expectedLoops)
  }

  if (otherInfos.length > 0) {
    return otherInfos.join(' | ')
  }

  return 'n/a'
}

function showError (error) {
  console.log(error)
  if (!error) return ''

  const value = []
  Object.entries(error).forEach(function (oneProperty) {
    console.log(oneProperty)
    if (oneProperty[1] === null) return
    value.push(oneProperty[0] + '=' + oneProperty[1])
  })

  return value.join(' // ')
}

function computeDuration (moduleData) {
  // const startingDayJs = dayjs(moduleData?.info?.startedAt)
  // let endingDayJs
  // if (moduleData?.info?.endedAt) {
  //   endingDayJs = dayjs(moduleData?.info?.endedAt)
  // } else {
  //   endingDayJs = dayjs()
  // }
  // console.log(moduleData?.info?.startedAt, startingDayJs, moduleData?.info?.endedAt, endingDayJs)
  // const duration = startingDayJs.diff(endingDayJs, 'second')
  // return duration

  const duration = timeDuration(moduleData?.info?.startedAt, moduleData?.info?.endedAt)
  return duration?.timeText
}
