
/**
 * Which are the months included in the series
 */
export function makeMonthSeries (context) {
  // console.log('makeMonthSeries', context)
  // console.log(context.dates?.min, context.dates.max)
  const statementData = context.data?.statementData

  const months = new Set()
  statementData?.forEach(function (oneEntry) {
    // console.log(context.dates?.min, oneEntry.month, context.dates.max)
    if (context.dates?.min && oneEntry.month < context.dates.min) return
    if (context.dates?.max && oneEntry.month > context.dates.max) return

    months.add(oneEntry.month)
  })
  // console.log('months', months)

  const monthsArray = Array.from(months)
  return monthsArray
}
