import { gridApi } from './update-view.js'

export function activateActions (parentElement) {
  console.log('activateActions', parentElement)
  const elementsWithExport = parentElement.querySelector('[d-action="exportTable"]')
  activateExport(elementsWithExport)
}

function activateExport (element) {
  console.log('activateExport', element)
  element.addEventListener('click', function () {
    if (!gridApi.exportDataAsCsv) return

    gridApi.exportDataAsCsv({
      skipPinnedTop: true
    })
  })
}
