// CSS import Not working
// import 'ag-grid-community/styles/ag-grid.css'

import { createGrid } from 'ag-grid-community'
import { transformDataset } from '../../../utilities/transform-dataset/transform-dataset.js'
import { moduleData } from '../../data.js'
import { ordersDetailsSummary } from '../../settings/outputs/orders-details-summary.js'
import { commerceContainerId } from '../../table-ui/show-table.js'

import htmlGridContainerTemplate from './grid-container.html'

export function showDataGrid () {
  console.log('showGrid')
  const dataset = moduleData[ordersDetailsSummary.sourceData]
  const propertySettings = ordersDetailsSummary.propertiesSettings

  const rows = transformDataset(dataset, propertySettings)
  // showTable(rows, ordersDetailsSummary.propertiesSettings)
  console.log('rows', rows)

  const columnDefs = []
  ordersDetailsSummary.propertiesSettings.forEach(function (property) {
    const colDef = {
      field: property.propertyName
    }
    columnDefs.push(colDef)
  })
  console.log('columnDefs', columnDefs)

  const gridOptions = {
    rowData: rows,
    columnDefs
  }

  console.log('gridOptions', gridOptions)

  const gridContainerId = commerceContainerId
  const gridElement = document.getElementById(gridContainerId)
  gridElement.innerHTML = htmlGridContainerTemplate
  console.log('gridElement', gridElement)

  const gridContainerElement = document.getElementById('gridContainer')
  const grid = createGrid(gridContainerElement, gridOptions)
  console.log('grid', grid)

  grid.exportDataAsCsv()
}
