// App config
import * as configInterceptors from './interceptors.js'

// Dependencies
import * as mIntercom from '../modules/intercom/intercom.js'
import * as mReferrals from '../../helpers/referrals/referrals.js'
import * as mFooter from '../modules/footer/footer.js'
import * as mUser from '../modules/user/user.js'
import * as mAccount from '../modules/user/account.js'
import * as mMenu from '../modules/header-menu/menu.js'
import { loadOneOrganisation } from '../modules/user/organisations.js'

// Track that User has been loaded (So navigating from one page to another does not force a reload)
let isUserLoaded = false
let isPageLoaded = false

configInterceptors.setFetchResponse()

/**
 *
 * @param {Object} params
 * @param {String} [params.accountId]
 * @param {String} [params.orgId]
 * @param {Boolean} [params.loadUser]
 * @param {Boolean} [params.loadChartOfAccounts]
 */
export async function loadPageStarter (params = {}) {
  console.log('*** loadPageStarter', 'isUserLoaded?', isUserLoaded, params)

  mFooter.loadFooter()

  mReferrals.readReferrer()

  // Load User by default
  const isForceLoadUser = params.loadUser === true
  const isDefaultLoadUserWhenNotAlready = typeof params.loadUser === 'undefined' && !isUserLoaded
  if (isForceLoadUser || isDefaultLoadUserWhenNotAlready || !isPageLoaded) {
    await mUser.loadUser()
    console.log('*** loadPageStarter loadUser() then')
    isUserLoaded = true
  } else {
    console.log('showUserHeader without loadUser()')
    mUser.showUserHeader()
  }

  if (params.accountId) {
    localStorage.setItem('accountId', params.accountId)
    await mAccount.load(params.accountId)
  } else {
    mAccount.useAccount(null)
  }

  if (params.orgId) {
    localStorage.setItem('orgId', params.orgId)
    await loadOneOrganisation(params.orgId)
  }

  if (params.loadChartOfAccounts) {
    await mAccount.addChartOfAccounts()
  }

  // TODO: update accountData() when not on an account related page?
  mMenu.show(mAccount.accountData())

  if (!isPageLoaded) {
    mIntercom.bootIntercom()
  } else {
    mIntercom.recordPage()
  }

  isPageLoaded = true

  console.log('*** loadPageStarter done **')
}

// function launchGoogleAnalytics () {
//   console.log('launchGoogleAnalytics?', window.location)
//   if (window.location.hostname === 'localhost') return
//   if (window.location.hostname.includes('staging')) return

//   console.log('launch Analytics')
//   gtag('config', 'G-XXX')
// }

// launchGoogleAnalytics()
