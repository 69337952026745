
export function makeRowPinnedTop (gridApi) {
  // const visibleRows = listOfVisibleRows(gridApi)

  const pinnedRows = []

  pinnedRows.push({
    name: 'Total'
  })

  return pinnedRows
}

export function listOfVisibleRows (gridApi) {
  const visibleRows = []

  gridApi?.forEachNodeAfterFilterAndSort(function (rowNode) {
    // console.log('rowNode', rowNode)
    visibleRows.push(rowNode.data)
  })

  console.log('visibleRows', visibleRows)
  return visibleRows
}
