import { fillString } from '../../../helpers/templating/string-template.js'
import { activateMenuCurrencies } from './currencies/options-currencies.js'
import { activateMenuEntities } from './entities/options-organisations.js'
import { activateOptionsPeriods, enableOptionsPeriods } from './periods/options-periods.js'
import filterDatesModule from './filter-dates/filter-dates-module.js'
import optionsJournalsModule from './options-journals/options-journals.js'
import { activateDecimals } from './others/options-others.js'

const selectorFilterDates = filterDatesModule()
const selectorOptionsJournals = optionsJournalsModule()

/**
 *
 */
const menuChoices = [
  {
    id: 'organisations',
    name: 'Entities',
    isShownByDefault: true,
    launchFunction: activateMenuEntities
  },
  {
    id: 'dates',
    name: 'Dates',
    isShownByDefault: true,
    enable: enableOptionsPeriods,
    launchFunction: activateOptionsPeriods
  },
  {
    id: 'filterDates',
    name: 'Filter Dates',
    enable: selectorFilterDates.enable,
    launchFunction: selectorFilterDates.openModule
  },
  {
    id: 'optionsJournals',
    name: 'View Options',
    enable: selectorOptionsJournals.enable,
    launchFunction: selectorOptionsJournals.openModule
  },
  {
    id: 'valueAggregation',
    name: 'Periods'
  },
  {
    id: 'periodicity',
    name: 'Periodicity'
  },
  {
    id: 'forecasting',
    name: 'Forecasting'
  },
  {
    id: 'gridLayout',
    name: 'Layout'
  },
  {
    id: 'currencies',
    name: 'Currencies',
    isShownByDefault: true,
    launchFunction: activateMenuCurrencies
  },
  {
    id: 'decimals',
    name: 'Decimals',
    launchFunction: activateDecimals
  }
]

/**
 * @param {*} selectorOptions
 */
export function makeMenu (selectorOptions, optionSelectorData) {
  console.log('makeMenu', selectorOptions, optionSelectorData)

  const menuContainer = document.getElementById('optionSelectorMenu')
  console.log('menuContainer', menuContainer)

  const menuHtml = []
  menuHtml.push('<div class="menu">')
  menuHtml.push('<p class="menu-label">Options</p>')
  menuHtml.push('<ul class="menu-list">')

  menuChoices.forEach(function (menuChoice) {
    if (!shouldShowMenu(selectorOptions, menuChoice)) return

    menuChoice.menuIsShown = true

    const baseHtml = '<li><a menu-id=":id">:name</a></li>'
    const choiceHtml = fillString(baseHtml, {
      id: menuChoice.id,
      name: menuChoice.name || menuChoices.id
    })

    menuHtml.push(choiceHtml)

    if (menuChoice.enable) {
      menuChoice.enable(selectorOptions, optionSelectorData)
    }
  })

  menuHtml.push('</ul>')
  menuHtml.push('</div>')

  menuContainer.innerHTML = menuHtml.join('')

  addListeners(selectorOptions, optionSelectorData)
  openDefault(selectorOptions, optionSelectorData)
}

/**
 *
 * @param {*} selectorOptions
 * @param {*} menuChoice
 * @returns
 */
function shouldShowMenu (selectorOptions, menuChoice) {
  if (selectorOptions?.hide?.includes(menuChoice.id)) return false
  if (selectorOptions?.show?.includes(menuChoice.id)) return true
  if (menuChoice?.isShownByDefault) return true
}

/**
 *
 * @param {*} selectorOptions
 * @returns
 */
function openDefault (selectorOptions, optionSelectorData) {
  if (selectorOptions.default) {
    const moduleToOpen = selectorOptions.default
    openModule(selectorOptions, moduleToOpen, optionSelectorData)
  }

  // Otherwise, get the first menu available
  const moduleToOpen = menuChoices.find(function (choice) {
    return choice.menuIsShown === true
  })
  if (!moduleToOpen) return
  openModule(selectorOptions, moduleToOpen, optionSelectorData)
}

/**
 *
 * @param {*} selectorOptions
 * @param {*} menuId
 */
function openModule (selectorOptions, menuId, optionSelectorData) {
  const menuChoice = findChoice(menuId)

  menuChoice?.launchFunction?.(selectorOptions, optionSelectorData)
}

function findChoice (menuId) {
  const menuChoice = menuChoices.find(function (choice) {
    return choice.id === menuId
  })
  return menuChoice
}

/**
 *
 * @param {*} selectorOptions
 */
function addListeners (selectorOptions, optionSelectorData) {
  const menuContainer = document.getElementById('optionSelectorMenu')
  menuContainer.addEventListener('click', function (clickEvent) {
    const targetElement = clickEvent.target.closest('a')
    if (!targetElement) return

    const menuId = targetElement.getAttribute('menu-id')
    openModule(selectorOptions, menuId, optionSelectorData)
  })
}
