import { parseDateString } from '../../../../utilities/parse-date-string.js'
import { parseNumber } from '../../../utilities/parse-number.js'

export const lazadaSettlementsFieldsMapping = [
  {
    inputName: 'Order No.', // 'Order Number',
    outputName: 'orderId'
  },
  {
    outputName: 'recordType',
    parseFunction: function () {
      return 'settlementLine'
    }
  },
  {
    outputName: 'orderPlatform',
    parseFunction: function (record, context) {
      return context?.platform || undefined
    }
  },
  {
    outputName: 'orderCountry',
    parseFunction: function (record, context) {
      return context?.country || undefined
    }
  },
  // Not available
  // {
  //   inputName: 'Order Creation Date',
  //   outputName: 'createdAt',
  //   parseFunction: function (value) {
  //     return parseDateString(value, { inputFormat: 'DD MMM YYYY' })
  //   }
  // },
  {
    inputName: 'Transaction Date',
    outputName: 'settledAt',
    parseFunction: function (value) {
      return parseDateString(value, { inputFormat: 'DD MMM YYYY' })
    }
  },
  //
  // Revenue
  {
    inputName: 'Item Price Credit',
    outputName: 'itemPriceCredit',
    parseFunction: parseNumber
  },
  {
    // MY, TH, PH
    inputName: 'Reversal Item Price',
    outputName: 'reversalItemPrice',
    parseFunction: parseNumber
  },
  {
    // MY, SG
    inputName: 'Wrong Weight Adjustment',
    outputName: 'wrongWeightAdjustment',
    parseFunction: parseNumber
  },
  {
    // TH, PH
    inputName: 'Lost Claim',
    outputName: 'lostClaim',
    parseFunction: parseNumber
  },
  {
    outputName: 'revenueGross',
    computeFormula: 'itemPriceCredit + reversalItemPrice + wrongWeightAdjustment + lostClaim',
    computeFormulaDecimals: 2
  },
  //
  // Discounts
  {
    // MY, SG
    inputName: 'Lazcoin discount',
    outputName: 'lazcoinDiscount',
    parseFunction: parseNumber
  },
  {
    // MY, TH, SG, PH
    inputName: 'Promotional Charges Vouchers',
    outputName: 'promotionalChargesVouchers',
    parseFunction: parseNumber
  },
  {
    // MY, TH, SG, PH
    inputName: 'Promotional Charges Flexi-Combo',
    outputName: 'promotionalChargesFlexiCombo',
    parseFunction: parseNumber
  },
  {
    // MY only
    inputName: 'Reversal Lazcoin discount',
    outputName: 'reversalLazcoinDiscount',
    parseFunction: parseNumber
  },
  {
    // MY, SG
    inputName: 'Lazada Bonus',
    outputName: 'lazadaBonus',
    parseFunction: parseNumber
  },
  {
    // MY, SG
    inputName: 'Lazada Bonus - LZD co-fund',
    outputName: 'lazadaBonusLzdCofund',
    parseFunction: parseNumber
  },
  {
    // TH, PH
    inputName: 'Reversal Promotional Charges Vouchers',
    outputName: 'reversalPromotionalChargesVouchers',
    parseFunction: parseNumber
  },
  {
    // TH
    inputName: 'Reversal Promotional Charges Flexi-Combo',
    outputName: 'reversalPromotionalChargesFlexiCombo',
    parseFunction: parseNumber
  },
  {
    outputName: 'discount',
    computeFormula: 'lazcoinDiscount + promotionalChargesVouchers + promotionalChargesFlexiCombo + reversalLazcoinDiscount + lazadaBonus + lazadaBonusLzdCofund + reversalPromotionalChargesVouchers + reversalPromotionalChargesFlexiCombo',
    computeFormulaDecimals: 2
  },
  {
    outputName: 'revenueNet',
    computeFormula: 'revenueGross + discount',
    computeFormulaDecimals: 2
  },
  //
  // Online Marketings
  {
    // MY, TH, SG, PH
    inputName: 'Sponsored Affiliates',
    outputName: 'sponsoredAffiliates',
    parseFunction: parseNumber
  },
  {
    // TH
    inputName: 'Buyer Review Incentive',
    outputName: 'buyerReviewIncentive',
    parseFunction: parseNumber
  },
  {
    outputName: 'onlineMarketingAds',
    computeFormula: 'sponsoredAffiliates + buyerReviewIncentive',
    computeFormulaDecimals: 2
  },
  //
  // Online Distribution Expenses
  {
    // MY, TH, SG, PH
    inputName: 'Free Shipping Max Fee',
    outputName: 'freeShippingMaxFee',
    parseFunction: parseNumber
  },
  {
    // MY, TH, PH
    inputName: 'Shipping fee Subsidy (By Seller)',
    outputName: 'shippingFeeSubsidyBySeller',
    parseFunction: parseNumber
  },
  {
    // MY, TH
    inputName: 'Reversal of Free Shipping Max Fee',
    outputName: 'reversalFreeShippingMaxFee',
    parseFunction: parseNumber
  },
  {
    // MY, TH, PH
    inputName: 'Shipping Fee Voucher Refund to Laz',
    outputName: 'shippingFeeVoucherRefundToLaz',
    parseFunction: parseNumber
  },
  {
    // SG
    inputName: 'Shipping Fee (Paid By Customer)',
    outputName: 'shippingFeePaidByCustomer',
    parseFunction: parseNumber
  },
  {
    // SG
    inputName: 'Shipping Fee Paid by Seller',
    outputName: 'shippingFeePaidBySeller',
    parseFunction: parseNumber
  },
  {
    // SG
    inputName: 'Shipping Fee Voucher (by Lazada)',
    outputName: 'shippingFeeVoucherByLazada',
    parseFunction: parseNumber
  },
  {
    // PH
    inputName: 'Shipping Fee Refund to Customer',
    outputName: 'shippingFeeRefundToCustomer',
    parseFunction: parseNumber
  },
  {
    outputName: 'onlineDistributionFees',
    computeFormula: 'freeShippingMaxFee + shippingFeeSubsidyBySeller + reversalFreeShippingMaxFee + shippingFeeVoucherRefundToLaz + shippingFeePaidByCustomer + shippingFeePaidBySeller + shippingFeeVoucherByLazada + shippingFeeRefundToCustomer',
    computeFormulaDecimals: 2
  },
  //
  // Platform fees
  {
    // MY, TH, SG, PH
    inputName: 'Commission',
    outputName: 'commission',
    parseFunction: parseNumber
  },
  {
    // MY, TH, SG, PH
    inputName: 'Payment Fee',
    outputName: 'paymentFee',
    parseFunction: parseNumber
  },
  {
    // MY, TH, PH
    inputName: 'Reversal Commission',
    outputName: 'reversalCommission',
    parseFunction: parseNumber
  },
  {
    // TH, PH
    inputName: 'Commission fee - correction for undercharge',
    outputName: 'commissionFeeCorrectionForUndercharge',
    parseFunction: parseNumber
  },
  {
    // TH, PH
    inputName: 'Payment fee - correction for undercharge',
    outputName: 'paymentFeeCorrectionForUndercharge',
    parseFunction: parseNumber
  },
  {
    // SG
    inputName: 'LazFlash Extra/Everyday Below $4.99 Subsidy',
    outputName: 'lazFlashExtraBelow499Subsidy',
    parseFunction: parseNumber
  },
  {
    // SG
    inputName: 'Preferred Commission',
    outputName: 'preferredCommission',
    parseFunction: parseNumber
  },
  {
    // PH
    inputName: 'Campaign Fee',
    outputName: 'campaignFee',
    parseFunction: parseNumber
  },
  {
    // PH
    inputName: 'Reversal Campaign Fee',
    outputName: 'reversalCampaignFee',
    parseFunction: parseNumber
  },
  {
    outputName: 'onlinePlatformFees',
    computeFormula: 'commission + paymentFee + reversalCommission + commissionFeeCorrectionForUndercharge + paymentFeeCorrectionForUndercharge + lazFlashExtraBelow499Subsidy + preferredCommission + campaignFee + reversalCampaignFee',
    computeFormulaDecimals: 2
  },
  //
  {
    outputName: 'settlementAmount',
    computeFormula: 'revenueNet + onlineMarketingAds + onlinePlatformFees + onlineDistributionFees',
    computeFormulaDecimals: 2
  },

  {
    outputName: 'Sponsored Solutions Top-up',
    computeFormula: 'sponsoredSolutionsTopup',
    computeFormulaDecimals: 2
  },
  {
    outputName: 'prepayments',
    computeFormula: 'sponsoredSolutionsTopup',
    computeFormulaDecimals: 2
  }
]
