// import dayjs from 'dayjs'
import { formatNumber } from '../../../../helpers/helpers/numbers.js'

export function makeColumnDefs (context) {
  const columnDefs = []

  columnDefs.push({
    headerName: 'Month',
    valueGetter: function (cellParams) {
      const dateMonth = cellParams.data.month
      if (!dateMonth) return null

      return dateMonth

      // const date = dayjs(data)
      // return date.format('YYYY-MM')
    },
    pinned: 'left'
  })

  columnDefs.push({
    headerName: 'Account',
    valueGetter: function (cellParams) {
      const account = cellParams.data.chartOfAccount
      if (!account) {
        console.warn('No account?', cellParams.data)
      }
      return account?.name
    },
    pinned: 'left'
  })

  // Amount
  columnDefs.push({
    headerName: 'From Journals',
    valueGetter: function (cellParams) {
      return cellParams.data.values?.journalsValues?.amount || null
    },
    valueFormatter,
    type: 'numericColumn'
  })

  columnDefs.push({
    headerName: 'Combined: Journals',
    valueGetter: function (cellParams) {
      return cellParams.data.values?.combinedValuesJournalsOnly?.amount || null
    },
    onCellClicked: function (cellParams) {
      console.log(cellParams)
    },
    valueFormatter,
    type: 'numericColumn'
  })

  columnDefs.push({
    headerName: 'From Trial Balances',
    valueGetter: function (cellParams) {
      return cellParams.data.values?.tbValues?.amount || null
    },
    valueFormatter,
    type: 'numericColumn'
  })

  columnDefs.push({
    headerName: 'Combined: Adjs',
    valueGetter: function (cellParams) {
      return cellParams.data.values?.combinedValuesAdjOnly?.amount || null
    },
    valueFormatter,
    type: 'numericColumn'
  })

  columnDefs.push({
    headerName: 'Combined: Totals',
    valueGetter: function (cellParams) {
      return cellParams.data.values?.combinedValuesTotal?.amount || null
    },
    valueFormatter,
    onCellClicked: function (cellParams) {
      console.log(cellParams)
    },
    type: 'numericColumn'
  })
  return columnDefs
}

function valueFormatter (cellParams) {
  if (cellParams.value === null) return null
  return formatNumber(cellParams.value, '2')
}
