import { makeXeroLink } from '../../../helpers/xero-links.js'
import { fillString } from '../../../../helpers/templating/string-template.js'
import * as mAccount from '../../../modules/user/account.js'

export function makeColDefs (crossContacts) {
  const colDefs = [
    {
      id: 'Entity',
      valueGetter: function (cellParams) {
        const organisation = mAccount.getOrganisationData(cellParams.rowDef._xeroOrganisation)

        return organisation.name
      },
      layout: 'left',
      width: 220
    },
    {
      id: 'Xero',
      valueGetter: function (cellParams) {
        const organisation = mAccount.getOrganisationData(cellParams.rowDef._xeroOrganisation)
        const link = makeXeroLink('Contact', {
          shortCode: organisation?.details.shortCode,
          xeroContactId: cellParams.rowDef?.xeroContactId
        })
        const linkHtml = fillString('<a href=":link" target="_blank"><i class="fa-solid fa-up-right-from-square right-margin"></i></a>', {
          link
        })
        return [linkHtml].join(' ')
      },
      layout: 'left',
      width: 50
    },
    {
      id: 'Contact',
      valueGetter: function (cellParams) {
        const contactName = cellParams.rowDef?.name || '??'

        return contactName
      },
      layout: 'left',
      width: 300,
      onClick: function (cellParams) {
        console.log(cellParams.rowDef)

        const organisation = mAccount.getOrganisationData(cellParams.rowDef._xeroOrganisation)
        const link = makeXeroLink('Contact', {
          shortCode: organisation?.details.shortCode,
          xeroContactId: cellParams.rowDef?.xeroContactId
        })
        console.log(link)
      }
    },
    {
      id: 'Xero to Xero Key',
      valueGetter: function (cellParams) {
        return cellParams.rowDef?.xeroNetworkKey || 'Not Set'
      },
      width: 250
    },
    {
      id: 'Status',
      valueGetter: function (cellParams) {
        return cellParams.rowDef?.status || 'Not Set'
      },
      width: 90
    }
  ]

  return colDefs
}
