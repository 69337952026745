import { showElement } from '../../../../helpers/dom.js'
import { syncManagementListeners } from './actions.js'

import htmlIndex from './index.html'

const containerId = 'organisationSyncManagement'

/**
 *
 * @param {*} organisationAccess
 */
export function showSyncManagement (orgId) {
  console.log('showSyncManagement', orgId)

  const containerElement = showElement(containerId)

  containerElement.innerHTML = htmlIndex

  containerElement.addEventListener('click', function (clickEvent) {
    syncManagementListeners(clickEvent, orgId)
  })
}
