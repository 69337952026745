// Router using https://visionmedia.github.io/page.js/
// Last Updated 2020: React, VueJS and Angular probably took over.
// Flo still likes the simplicity of this front end router
//
import page from 'page'

// Admin
import { showPageListOrgs } from './pages/admin/orgs/index.js'
import { loadPageStarter } from './config/app-starter.js'
import { showPageListAccounts } from './pages/admin/accounts/index.js'
import { showPageSyncs } from './pages/admin/xero-syncs/index.js'
import { showPageTokens } from './pages/admin/tokens/index.js'
import { showOrgTrialBalances } from './pages/admin/trial-balances/trial-balances.js'
import { showOrgAccounts } from './pages/admin/org-accounts/index.js'
import { showOrgInfos } from './pages/admin/org-info/infos.js'
import { showPageOneSync } from './pages/admin/one-sync/show-one-sync.js'
import { showSourceInfos } from './pages/admin/info-source/show-source-infos.js'

//
page('/admin', async function (context) {
  console.log('/admin')
  await loadPageStarter()
})

//
page('/admin/accounts', async function (context) {
  console.log('/admin/accounts')
  await loadPageStarter()

  showPageListAccounts()
})

//
page('/admin/orgs', async function (context) {
  console.log('/admin/orgs')
  await loadPageStarter()

  showPageListOrgs()
})

// Listing of Syncs: all org or a specific org
page('/admin/syncs/:orgId?', async function (context) {
  await loadPageStarter()

  const { orgId } = context.params
  showPageSyncs(orgId)
})

/**
 * Details of a Sync
*/
page('/admin/sync/:syncId', async function (context) {
  await loadPageStarter()

  const { syncId } = context.params
  showPageOneSync(syncId)
})

page('/admin/tokens/:orgId?', async function (context) {
  await loadPageStarter()

  const { orgId } = context.params

  showPageTokens(orgId)
})

page('/admin/org-info/:orgId', async function (context) {
  const { orgId } = context.params
  await loadPageStarter()

  showOrgAccounts(orgId)
})

page('/admin/org-info/:orgId/trial-balances', async function (context) {
  const { orgId } = context.params
  await loadPageStarter()

  showOrgTrialBalances(orgId)
})

page('/admin/org-info/:orgId/infos', async function (context) {
  const { orgId } = context.params
  await loadPageStarter()

  showOrgInfos(orgId)
})

page('/admin/source-info/:xeroSourceId', async function (context) {
  const { xeroSourceId } = context.params
  await loadPageStarter()

  showSourceInfos(xeroSourceId)
})

// Init the router
page()
