import { createGrid } from 'ag-grid-community'
import { moduleData } from '../../data.js'
import { commerceContainerId } from '../../table-ui/show-table.js'
import { combineOrdersAndSettlements } from '../../utilities/combine-orders-and-settlements/combine-orders-and-settlements.js'

import htmlGridContainerTemplate from './grid-container.html'

/**
 * orderId
 */
export function showDataGrid () {
  const combinationMap = combineOrdersAndSettlements(moduleData.ordersLines, moduleData.settlements)

  const gridOptions = {
    rowData: makeRows(combinationMap),
    columnDefs: makeColumnDefs()
  }

  console.log('gridOptions', gridOptions)

  const gridContainerId = commerceContainerId
  const gridElement = document.getElementById(gridContainerId)
  gridElement.innerHTML = htmlGridContainerTemplate
  console.log('gridElement', gridElement)

  const gridContainerElement = document.getElementById('gridContainer')
  const grid = createGrid(gridContainerElement, gridOptions)
  console.log('grid', grid)
}

function makeRows (combinationMap) {
  console.log('makeRows', combinationMap)
  const rows = Array.from(combinationMap, function (record) {
    const row = {
      orderId: record[0]
    }

    Object.keys(record[1])?.forEach(function (key) {
      row[key] = record[1][key]
    })
    return row
  })

  console.log('rows', rows)
  return rows
}

function makeColumnDefs () {
  const columnDefs = []

  // OrderId
  columnDefs.push({
    field: 'orderId'
  })

  columnDefs.push(makeOrderSummaryColDefs())
  columnDefs.push(makeSettlementSummaryColDefs())
  columnDefs.push(makeDiscrepancyColumns())
  console.log('columnDefs', columnDefs)

  return columnDefs
}

function makeOrderSummaryColDefs () {
  const colDef = {
    headerName: 'Order Info',
    children: []
  }

  colDef.children.push(
    {
      headerName: 'createdAt',
      valueGetter: function (cellParams) {
        return cellParams.data.summaryOrder?.createdAt
      }
    },
    {
      headerName: 'revenueGross',
      valueGetter: function (cellParams) {
        return cellParams.data.summaryOrder?.revenueGross
      }
    },
    {
      headerName: 'revenueNet',
      valueGetter: function (cellParams) {
        return cellParams.data.summaryOrder?.revenueNet
      }
    }
  )
  return colDef
}

function makeSettlementSummaryColDefs () {
  const colDef = {
    headerName: 'Settlement Info',
    children: []
  }

  colDef.children.push(
    {
      headerName: 'settledAt',
      valueGetter: function (cellParams) {
        return cellParams.data.summarySettlement?.settledAt
      }
    },
    {
      headerName: 'revenueGross',
      valueGetter: function (cellParams) {
        return cellParams.data.summarySettlement?.revenueGross
      }
    },
    {
      headerName: 'revenueNet',
      valueGetter: function (cellParams) {
        return cellParams.data.summarySettlement?.revenueNet
      }
    }
  )
  return colDef
}

function makeDiscrepancyColumns () {
  const orderColDefs = {
    headerName: 'Discrepancies?',
    children: []
  }

  orderColDefs.children.push(
    {
      headerName: 'revenueGross matching',
      valueGetter: function (cellParams) {
        return cellParams.data.comparisons?.revenueGross?.isMatching
      }
    },
    {
      headerName: 'revenueGross variance',
      valueGetter: function (cellParams) {
        return cellParams.data.comparisons?.revenueGross?.variance
      }
    },
    {
      headerName: 'revenueNet matching',
      valueGetter: function (cellParams) {
        return cellParams.data.comparisons?.revenueNet?.isMatching
      }
    },
    {
      headerName: 'revenueNet variance',
      valueGetter: function (cellParams) {
        return cellParams.data.comparisons?.revenueNet?.variance
      }
    }

  )
  return orderColDefs
}
