import { createGrid } from 'ag-grid-community'
import { hideElement, showElement } from '../../../helpers/dom.js'
import { optionSelectorData } from '../../../modules/option-selector/data.js'
import { loadDataJournals } from './load-data.js'
import { appAccount } from '../../../modules/user/account.js'
import { setGridContent } from './set-grid-content.js'

const context = {
  data: {}
}

export let gridApi

/**
 *
 */
export async function updateView () {
  console.log('updateView', arguments)
  console.log('optionSelectorData', optionSelectorData)
  showElement('statementLoadingMessage')
  hideElement('statementContainer')

  // Params in use
  context.showOrgId = optionSelectorData.organisationId || context.showOrgId
  context.currencySymbol = optionSelectorData.currencySymbol || 'XXX'
  context.viewDecimals = optionSelectorData.decimals || 0
  context.filterDates = optionSelectorData.filterDates
  context.optionsJournals = optionSelectorData.optionsJournals
  context.accountData = appAccount.accountData()
  // context.groupings = ['accounts', 'tracking1', 'tracking2']

  // Load data
  // TODO: we should not need to reload if it's optionsJournals which changed
  const response = await loadDataJournals(context)
  console.log('context', context)
  context.data = response

  const gridContent = setGridContent(context)

  if (gridApi) {
    gridApi.updateGridOptions({
      rowData: gridContent.rows,
      columnDefs: gridContent.columnDefs
    })
  } else {
    const gridOptions = {
      rowData: gridContent.rows,
      columnDefs: gridContent.columnDefs,
      defaultColDef: {
        filter: true,
        floatingFilter: true
      },
      autoSizeStrategy: {
        type: 'fitCellContents'
      },
      context
    }

    // Include Top Pinned row with total of amounts

    const containerId = 'grid'
    const gridContainerElement = document.getElementById(containerId)
    gridApi = createGrid(gridContainerElement, gridOptions)
  }

  console.log('gridApi', gridApi)

  hideElement('statementLoadingMessage')
  showElement('statementContainer')
}
