import init from './init.js'
import renderHtmlElement from './render.js'

/**
 *
 * @param {Object} options
 * @param {String} options.containerId ID of the HTML element the selector will be rendered in
 * @param {Object} [options.labels]
 * @param {String} [options.labels.main=Selector] Defaults to Selector
 * @param {String} [options.labels.from=From] Defaults to From
 * @param {String} [options.labels.to=To] Defaults to To
 *
 * @param {String} [options.values]
 * @param {String} [options.values.from]
 * @param {String} [options.values.to]
 * @returns Instance of the selector
 */
export default function dateRangeSelector (options) {
  const data = {
    selectorId: null,
    containerId: null,
    callbacks: {},
    values: {
      from: null,
      to: null
    },
    labels: {
      // Includes defaults
      main: 'Selector',
      from: 'From',
      to: 'To'
    }
  }

  function setLabels (newLabels) {
    if (newLabels.main) {
      data.labels.main = newLabels.main
    }

    if (newLabels.from) {
      data.labels.from = newLabels.from
    }

    if (newLabels.to) {
      data.labels.to = newLabels.to
    }
  }

  function setValues (from, to) {
    console.log('setValues', arguments)
    if (from) {
      data.values.from = from
    }
    if (to) {
      data.values.to = to
    }
  }

  function getValues () {
    return data.values
  }

  function render () {
    return renderHtmlElement(privateInstance)
  }

  const publicAPI = {
    getValues,
    setValues,
    setLabels,
    render,
    getContainerId: function () {
      return data.containerId
    }
  }

  const privateInstance = {
    data,
    api: publicAPI
  }

  init(privateInstance, options)

  // Publicly exposed instance methods
  return publicAPI
}

/*
const s1 = dateRangeSelector()
const s2 = dateRangeSelector()

s1.setValues(1, 10)
s2.setValues(2, 200)

s1.getValues()
s2.getValues()
*/
