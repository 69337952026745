import onChange from './on-change.js'

export default function addListeners (privateInstance) {
  const htmlElement = privateInstance.data?.htmlElement

  const inputElements = htmlElement.querySelectorAll('input')

  inputElements.forEach(function (element) {
    element.addEventListener('change', function (event) {
      onChange(privateInstance)
    })
  })
}
