import enableOptionsJournals from './enable.js'
import renderOptionsJournals from './render.js'

export default function optionsJournalsModule () {
  const data = {
    grouping: null,
    htmlElement: null
  }

  function setValue (value) {
    const allowed = ['byAccount', 'detailedEntries']
    if (allowed.includes(value)) data.grouping = value
  }

  function getValue () {
    return data.grouping
  }

  function enable (options, optionSelectorData) {
    return enableOptionsJournals(options, optionSelectorData, privateInstance)
  }

  function openModule (options, optionSelectorData) {
    return renderOptionsJournals(options, optionSelectorData, privateInstance)
  }

  const publicAPI = {
    enable,
    openModule,
    getValue,
    setValue
  }

  const privateInstance = {
    callbacks: {},
    api: publicAPI,
    data
  }

  return publicAPI
}
