import { createGrid } from 'ag-grid-community'
import { makeRowData } from './ag-grid/row-data.js'
import { makeColumnDefs } from './ag-grid/column-defs.js'
import { setElementHtml } from '../../../helpers/dom.js'

const containerId = 'syncsList'

/**
 *
 */
export function showSyncsAgGrid (listOfSyncs) {
  const gridOptions = {
    rowData: makeRowData(listOfSyncs),
    columnDefs: makeColumnDefs(),
    defaultColDef: {
      filter: true,
      floatingFilter: true,
      onCellClicked: function (cellParams) {
        console.log(cellParams.data)
      }
    },
    autoSizeStrategy: {
      type: 'fitCellContents'
    }
  }
  console.log('gridOptions', gridOptions)

  setElementHtml(containerId, '')
  document.getElementById(containerId).classList.add('ag-theme-quartz')

  const gridContainerElement = document.getElementById(containerId)
  const gridApi = createGrid(gridContainerElement, gridOptions)
  return gridApi
}
