import makeColumnDefsForAccounts from './by-account/make-column-defs.js'
import makeAccountsRows from './by-account/make-rows.js'
import makeColumnDefs from './detailed-entries/make-column-defs.js'
import makeEntriesRows from './detailed-entries/make-rows.js'

/**
 * Make rows and columns based on the options:
 * detailedEntries
 * byAccount
 *
 * @param {*} context
 * @returns
 */
export function setGridContent (context) {
  console.log('setGridContent', context)
  const show = 'byAccount'
  // const show = 'detailedEntries'

  const viewToShow = context.optionsJournals?.grouping || show

  let gridContent
  if (viewToShow === 'byAccount') {
    gridContent = {
      rows: makeAccountsRows(context),
      columnDefs: makeColumnDefsForAccounts(context)
    }
  } else {
    gridContent = {
      rows: makeEntriesRows(context),
      columnDefs: makeColumnDefs(context)
    }
  }

  console.log('gridContent', gridContent)

  return gridContent
}
